import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Box, Flex } from 'rebass';
import TextInput from '../_common/components/forms/TextInput';
import { Error } from '../_common/components/forms/FormError';
import Button from '../_common/components/Button';
import {
  required,
  composeValidators,
  passwordLength,
  passwordsMustMatch,
} from '../utils/formValidators';

const ResetPasswordForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" mb={40}>
          <Box width={[1, 0.5, 0.5]}>
            <Field
              label="New password"
              name="password"
              type="password"
              width={1}
              component={TextInput}
              validate={composeValidators(required, passwordLength(6))}
            />
            <Field
              label="Confirm password"
              name="passwordConfirmation"
              type="password"
              width={1}
              component={TextInput}
              validate={composeValidators(required, passwordsMustMatch)}
            />
          </Box>
        </Flex>
        {submitError && (
          <Flex mt={-20} mb={20} justifyContent="center">
            <Error>{submitError}</Error>
          </Flex>
        )}
        <Flex justifyContent="center">
          <Button
            type="submit"
            loading={submitting}
            disabled={submitting}
            minWidth={211}
          >
            Save
          </Button>
        </Flex>
      </form>
    )}
  />
);

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;

import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  Flex, Image, Text, Box,
} from 'rebass';
import styled from 'styled-components';
import Modal from '../_common/components/Modal';
import AddonConfirmationForm from './AddonConfirmationForm';
import HorizontalLine from '../_common/components/HorizontalLine';
import SuccessModal from './SuccessModal';
import { FONT_FAMILY } from '../theme';

const modalContentStyles = { maxWidth: 686 };

const TickBox = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(24, 33, 47, 0.15);
`;

@inject('addonsStore')
@observer
class RequestAddonModal extends React.Component {
  @observable isSuccessModalOpen = false;

  render() {
    const {
      onCancel,
      isOpen,
      addonName,
      additionalInfo,
      isRecurring,
      addonId,
      addonsStore: { requestAddon },
    } = this.props;
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={onCancel}
          customStyles={{ content: modalContentStyles }}
          onRequestClose={onCancel}
        >
          <Flex flexDirection="column" p={[20, 40]} height="100%">
            <Text fontSize={[22, 28]} fontFamily={FONT_FAMILY.HAILSR_BOLD} mb={[10, 15, 20]} color="seaGreen">
              {"You're requesting the extras"}
            </Text>
            <HorizontalLine mb={[10, 20, 30]} />
            <Flex alignItems="center" mb={[16, 20]}>
              <TickBox mr="8px">
                <Image
                  src="/images/icons/green-tick.svg"
                  style={{
                    position: 'relative',
                    top: '-9px',
                    left: '4px',
                  }}
                />
              </TickBox>
              <Text fontSize={[16, 18, 21]} fontFamily={FONT_FAMILY.HAILSR_BOLD} style={{ wordBreak: 'break-all' }}>
                {addonName}
              </Text>
            </Flex>
            <AddonConfirmationForm
              onSubmit={values => requestAddon({ ...values, addonId }, () => { this.isSuccessModalOpen = true; onCancel(); })}
              additionalInfo={additionalInfo}
              isRecurring={isRecurring}
              onCancel={onCancel}
            />
          </Flex>
        </Modal>
        <SuccessModal
          isOpen={this.isSuccessModalOpen}
          onCancel={() => {
            this.isSuccessModalOpen = false;
          }}
        />
      </>
    );
  }
}

RequestAddonModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addonName: PropTypes.string.isRequired,
  addonId: PropTypes.string.isRequired,
  addonsStore: PropTypes.object,
  additionalInfo: PropTypes.string,
  isRecurring: PropTypes.bool,
};

export default RequestAddonModal;

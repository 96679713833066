import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  Box, Flex, Text, Link,
} from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '../_app/routes';
import { FONT_FAMILY } from '../theme';
import DashboardItem from './DashboardItem';


const MyLink = styled(Link)`
  color: #25b3b0;
  text-decoration: underline;
  :hover {
    font-weight: 600;
    cursor: pointer;
  }
`;

@inject('userStore')
@observer
class Dashboard extends Component {
  render() {
    const {
      userStore: {
        profile,
      },
    } = this.props;
    const name = get(profile, 'firstName');
    return (
      <Fragment>
        <Flex mb={50}>
          <Text
            fontFamily={FONT_FAMILY.HAILSR_BOLD}
            lineHeight={1}
            fontSize={40}
            color="white"
            mr={10}
          >
            {'Come on in,'}
          </Text>
          {name && (
            <Text
              fontFamily={FONT_FAMILY.HAILSR_BOLD}
              lineHeight={1}
              fontSize={40}
              color="seaGreen"
            >
              {name}
            </Text>
          )}
        </Flex>
        <Flex mb={50}>
          <Text
            lineHeight={1}
            fontSize={23}
            color="white"
          >
            Please read our&nbsp;
            <MyLink href="https://work.life/covid-19-policy/" target="_blank" rel="noopener noreferrer">COVID-19 policy</MyLink>
          </Text>
        </Flex>
        <Flex flexWrap="wrap" mx={-10}>
          <Box width={[1, 1 / 2, 1 / 3]} p={10}>
            <DashboardItem
              link={APP_ROUTES.TEAM_MANAGEMENT}
              title="Team Management"
              description="Add/remove users, manage card assignment"
              iconUrl="/images/dashboard/team-management.png"
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 3]} p={10}>
            <DashboardItem
              link={APP_ROUTES.PAYMENTS}
              title="Payments & Billing"
              description="View invoices, change payment method & view upcoming transactions"
              iconUrl="/images/dashboard/payments-and-billing.png"
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 3]} p={10}>
            <DashboardItem
              link={APP_ROUTES.ACCOUNT}
              title="Account Details"
              description="Manage your account details and share and revoke access to the portal"
              iconUrl="/images/dashboard/account.png"
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 3]} p={10}>
            <DashboardItem
              link={APP_ROUTES.SUPPORT}
              title="Help Centre"
              description="Report issues and share feedback"
              iconUrl="/images/dashboard/help-centre.png"
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 3]} p={10}>
            <DashboardItem
              link={APP_ROUTES.EXTRAS}
              title="Extras"
              description="A selection of curated extras to tailor the space to your team"
              iconUrl="/images/icons/add-on-icon.svg"
            />
          </Box>
        </Flex>
      </Fragment>
    );
  }
}

Dashboard.wrappedComponent.propTypes = {
  userStore: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    fetchProfile: PropTypes.func.isRequired,
  }).isRequired,
};

export default Dashboard;

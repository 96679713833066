import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Flex } from 'rebass';
import Button from '../_common/components/Button';
import { Error } from '../_common/components/forms/FormError';
import TextInput, { TEXT_INPUT_VARIANT } from '../_common/components/forms/TextInput';
import { BUTTON_VARIANT } from '../theme';
import {
  composeValidators, mustBeValidEmail, passwordLength, passwordsMustMatch, required,
} from '../utils/formValidators';

const ProfileForm = ({ onSubmit, initialValues }) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({
      handleSubmit, pristine, submitting, submitError,
    }) => (
      <form onSubmit={handleSubmit}>
        <Flex mx={-15} flexDirection={['column', 'row', 'row']}>
          <Flex flexDirection="column" mb={[0, 20, 20]} width={[1, 1, 0.5]} px={15}>
            <Field
              name="firstName"
              label="First name"
              component={TextInput}
              validate={required}
              placeholder="First name"
              variant={TEXT_INPUT_VARIANT.GHOST}
            />
            <Field
              name="lastName"
              label="Last name"
              component={TextInput}
              validate={required}
              placeholder="Last name"
              variant={TEXT_INPUT_VARIANT.GHOST}
            />
            <Field
              name="phone"
              label="Phone"
              placeholder="Phone"
              component={TextInput}
              variant={TEXT_INPUT_VARIANT.GHOST}
              parse={value => value || ''}
            />
            <Field
              name="email"
              label="Email"
              component={TextInput}
              validate={composeValidators(required, mustBeValidEmail)}
              variant={TEXT_INPUT_VARIANT.GHOST}
            />
          </Flex>
          <Flex flexDirection="column" mb={20} width={[1, 1, 0.5]} px={15}>
            <Field
              name="oldPassword"
              label="Old password"
              type="password"
              component={TextInput}
              variant={TEXT_INPUT_VARIANT.GHOST}
            />
            <Field
              name="password"
              label="New password"
              type="password"
              component={TextInput}
              validate={passwordLength(6)}
              variant={TEXT_INPUT_VARIANT.GHOST}
            />
            <Field
              name="passwordConfirmation"
              label="Repeat password"
              type="password"
              component={TextInput}
              validate={composeValidators(passwordsMustMatch)}
              variant={TEXT_INPUT_VARIANT.GHOST}
            />
          </Flex>
        </Flex>
        {submitError && <Error>{submitError}</Error>}
        <Button
          type="submit"
          disabled={pristine || submitting}
          mt={20}
          variant={BUTTON_VARIANT.SECONDARY}
          loading={submitting}
        >
          Save
        </Button>
      </form>
    )}
  />
);

ProfileForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileForm;

import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect, { components } from 'react-select';
import { Card, Flex } from 'rebass';
import { CARD_VARIANT, colors, FONT_FAMILY } from '../../../theme';
import FormError from './FormError';
import Label from './FormLabel';

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected
      ? colors.seaGreen
      : state.isFocused
        ? colors.seaGreen
        : 'transparent',
    padding: 13,
    ':active': {
      backgroundColor: colors.seaGreen,
      outline: 'none',
    },
  }),
  control: provided => ({
    ...provided,
    borderRadius: 5,
    padding: 6,
    fontFamily: FONT_FAMILY.HAILSR_BOOK,
    boxShadow: 'none',
    border: '1px solid #979797',
    '&:hover': {
      border: '1px solid #979797',
    },
  }),
  menuList: provided => ({
    ...provided,
    padding: '0px',
    borderRadius: 5,
    boxShadow: 'none',
  }),
  menu: provided => ({
    ...provided,
    fontFamily: FONT_FAMILY.HAILSR_BOOK,
    zIndex: 10,
    boxShadow: 'none',
    borderRadius: 5,
  }),
};

export const MenuList = props => (
  <Card variant={CARD_VARIANT.TERTIARY} px={0} py={0}>
    <components.MenuList {...props}>{props.children}</components.MenuList>
  </Card>
);

MenuList.propTypes = {
  children: PropTypes.node,
};

export const IndicatorSeparator = () => null;

const Select = ({
  children, input, meta, options, label, width, ...props
}) => {
  const [selectedValue, setSelectedValue] = React.useState(null);
  React.useEffect(() => {
    const value = get(options.filter(option => input.value === option.value), '[0]', null);
    setSelectedValue(value);
  }, [input.value]);

  return (
    <Flex flexDirection="column" width={width}>
      {label && <Label>{label}</Label>}
      <ReactSelect
        {...input}
        {...props}
        options={options}
        styles={customStyles}
        onChange={(e) => {
          input.onChange(e.value);
          if (props.onChange) {
            props.onChange(e.value);
          }
        }}
        value={selectedValue}
        components={{ IndicatorSeparator, MenuList }}
        isSearchable={false}
      />
      <FormError meta={meta} />
    </Flex>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  width: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

Select.defaultProps = {
  width: 169,
  options: [],
};

export default Select;

import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';


export class InvoicesStore {
  defaultLimit = 5;

  @observable isLoading = false;
  @observable invoices = [];
  @observable error = null;
  @observable page = 1;
  @observable hasMore = true;

  @action
  fetchInvoices = async (refetch = false) => {
    this.isLoading = true;
    this.error = null;
    if (refetch) {
      this.invoices = [];
      this.page = 1;
      this.hasMore = true;
    }
    try {
      const {
        data: { data = [], total },
      } = await API(`${API_ROUTES.MY_INVOICES}?page=${this.page}&limit=${this.defaultLimit}`);
      this.invoices = [...this.invoices, ...data];
      this.page++;
      this.hasMore = this.invoices.length < total;
    } catch (e) {
      toast.error(e.message || "Couldn't fetch invoices.");
      this.error = true;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new InvoicesStore();

import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Flex } from 'rebass';
import TextInput, { TEXT_INPUT_VARIANT } from '../_common/components/forms/TextInput';
import AutoSubmit from '../_common/components/forms/AutoSubmit';

const debounceInterval = 1000;
const SearchForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({
      handleSubmit,
    }) => (
      <form onSubmit={handleSubmit}>
        <AutoSubmit debounce={debounceInterval} submit={handleSubmit} />
        <Flex width="fit-content">
          <Field
            name="query"
            placeholder="Search"
            component={TextInput}
            variant={TEXT_INPUT_VARIANT.SECONDARY}
            width={['auto', 342]}
            icon="/images/icons/search-icon.png"
          />
        </Flex>
      </form>
    )}
  />
);

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SearchForm;

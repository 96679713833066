import { inject, observer } from 'mobx-react/index';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Text } from 'rebass';
import { FONT_FAMILY } from '../theme';
import SignInWithTokenForm from './SignInWithTokenForm';

@inject('authStore')
@withRouter
@observer
class SignInWithToken extends Component {
  onSubmit = ({ password }) => {
    const { token } = this.props.match.params;
    const { signInWithToken } = this.props.authStore;
    return signInWithToken({ password, token });
  };

  render() {
    const { search } = this.props.location;
    const { space, name } = queryString.parse(search);
    return (
      <Fragment>
        <Text
          mt={50}
          fontSize={70}
          textAlign="center"
          color="white"
          lineHeight={1}
        >
          {`Welcome ${name || ''}`}
        </Text>
        <Text
          fontSize={25}
          fontFamily={FONT_FAMILY.HAILSR_BOOK}
          textAlign="center"
          color="white"
          lineHeight={1}
          mb={40}
        >
          {`from ${space || 'your space'}`}
        </Text>
        <Text
          fontSize={35}
          fontFamily={FONT_FAMILY.HAILSR_BOOK}
          textAlign="center"
          color="black"
          lineHeight={1}
          mb={40}
        >
          {'Let\'s get you set up.'}
        </Text>
        <SignInWithTokenForm onSubmit={this.onSubmit} />
      </Fragment>
    );
  }
}

SignInWithToken.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    signInWithToken: PropTypes.func,
  }).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SignInWithToken;

import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Flex } from 'rebass';
import Button from '../_common/components/Button';
import { AbsolutePositionedErrorsForm, Error } from '../_common/components/forms/FormError';
import TextInput from '../_common/components/forms/TextInput';
import {
  composeValidators, passwordLength, passwordsMustMatch, required,
} from '../utils/formValidators';

const SignInWithTokenForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, submitting }) => (
      <AbsolutePositionedErrorsForm onSubmit={handleSubmit}>
        <Flex justifyContent="center" alignItems="center" flexDirection={['column', 'row', 'row']} mb={40}>
          <Box mb={[20, 0, 0]}>
            <Field
              name="password"
              type="password"
              placeholder="Create Password"
              component={TextInput}
              width={279}
              validate={composeValidators(required, passwordLength(6))}
            />
          </Box>
          <Box width={16} />
          <Field
            name="passwordConfirmation"
            type="password"
            placeholder="Confirm password"
            component={TextInput}
            width={279}
            validate={composeValidators(required, passwordsMustMatch)}
          />
        </Flex>
        {submitError && (
          <Flex mt={-20} mb={20} justifyContent="center">
            <Error>{submitError}</Error>
          </Flex>
        )}
        <Flex justifyContent="center">
          <Button
            type="submit"
            loading={submitting}
            disabled={submitting}
            minWidth={211}
          >
            {'I\'m ready!'}
          </Button>
        </Flex>
      </AbsolutePositionedErrorsForm>
    )}
  />
);

SignInWithTokenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignInWithTokenForm;

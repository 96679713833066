import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';
import NavBar from './components/navigation/NavBar';
import BaseLayout from './layouts/BaseLayout';
import { APP_ROUTES } from '../_app/routes';

@inject('authStore')
@observer
class AppRoute extends React.Component {
  render() {
    const {
      layout: Layout,
      component: Component,
      isPrivate,
      navBar: Navigation,
      bgColor,
      ...routeProps
    } = this.props;
    const { isAuthenticated } = this.props.authStore;

    return (
      <Route
        {...routeProps}
        render={(props) => {
          if (!isAuthenticated && isPrivate) {
            return <Redirect to={APP_ROUTES.SIGN_IN} />;
          }
          return (
            <>
              <Navigation />
              <Layout pt={100} bgColor={bgColor}>
                <Component
                  {...routeProps}
                  {...props}
                  isAuthenticated={isAuthenticated}
                />
              </Layout>
            </>
          );
        }}
      />
    );
  }
}

AppRoute.wrappedComponent.propTypes = {
  component: PropTypes.func,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
  isPrivate: PropTypes.bool,
  navBar: PropTypes.func,
  path: PropTypes.string,
  layout: PropTypes.func,
  bgColor: PropTypes.string,
};

AppRoute.wrappedComponent.defaultProps = {
  isPrivate: false,
  layout: BaseLayout,
  navBar: NavBar,
};

export default AppRoute;

import PropTypes from 'prop-types';
import React from 'react';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import Button from '../_common/components/Button';
import HorizontalLine from '../_common/components/HorizontalLine';
import Modal from '../_common/components/Modal';
import { BUTTON_VARIANT } from '../theme';

const modalContentStyles = { maxWidth: 510, padding: 40 };

const DeleteUserModal = ({
  onConfirm, onCancel, email,
}) => (
  <Modal
    isOpen
    onClose={onCancel}
    customStyles={{ content: modalContentStyles }}
    onRequestClose={onCancel}
  >
    <Flex flexDirection="column">
      <Text fontSize={27} mb={20}>
        {'You\'ve chosen to delete user:'}
      </Text>
      <Flex alignItems="center">
        <Image src="/images/icons/x-icon.png" mr={10} />
        <Text color="red" fontSize={19} style={{ wordBreak: 'break-all' }}>
          {email}
        </Text>
      </Flex>
      <HorizontalLine my={20} />
      <Text fontSize={23} mb={20}>Are you sure?</Text>
      <Flex>
        <Button width={1} variant={BUTTON_VARIANT.SECONDARY} mr={20} onClick={onConfirm}>Yes</Button>
        <Box width={25} />
        <Button width={1} variant={BUTTON_VARIANT.SECONDARY} onClick={onCancel}>No</Button>
      </Flex>
    </Flex>
  </Modal>
);

DeleteUserModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  email: PropTypes.string,
};

export default DeleteUserModal;

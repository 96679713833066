import ReactGA from 'react-ga';

const { REACT_APP_GA } = process.env;

export const initializeGAPageView = (history) => {
  if (REACT_APP_GA) {
    ReactGA.initialize(REACT_APP_GA);
    ReactGA.pageview(window.location.pathname);
    history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
  }
};

import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Image } from 'rebass';
import styled, { css } from 'styled-components';
import { FONT_FAMILY } from '../../../theme';
import FormError from './FormError';
import Label from './FormLabel';

export const TEXT_INPUT_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  GHOST: 'ghost',
  GHOST_SECONDARY: 'ghostSecondary',
};

const styles = css`
  display: block;
  background-color: #ffffff;
  color: #5b5b5b;
  padding: 13px;
  ${props => props.icon && css`
    padding: 13px 60px 13px 13px;
  `}
  outline: none;
  border-radius: 5px;
  border: 0 none;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.5);
  font-size: 17px;
  font-family: ${props => props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
  width: 100%;
  
  ${props => props.variant === TEXT_INPUT_VARIANT.SECONDARY && css`
    box-shadow: none;
    border: 1px solid rgba(24, 33, 47, 0.15);
    color: rgba(111, 111, 111, 1);
    
    ::placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: rgba(111, 111, 111, 0.5);
    }
    
    :-ms-input-placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: rgba(111, 111, 111, 0.5);
    }
    
    ::-ms-input-placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: rgba(111, 111, 111, 0.5);
    }
  `}
  
  ${props => props.variant === TEXT_INPUT_VARIANT.GHOST && css`
    box-shadow: none;
    border: 1px solid #979797;
    color: white;
    background-color: transparent;
    
    ::placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: #6f6f6f;
    }
    
    :-ms-input-placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: #6f6f6f;
    }
    
    ::-ms-input-placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: #6f6f6f;
    }
  `}
  
  ${props => props.variant === TEXT_INPUT_VARIANT.GHOST_SECONDARY && css`
    box-shadow: none;
    border: 1px solid #979797;
    color: ${props.theme.colors.black};
    background-color: transparent;
    
    ::placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: #6f6f6f;
    }
    
    :-ms-input-placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: #6f6f6f;
    }
    
    ::-ms-input-placeholder {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: #6f6f6f;
    }
  `}
`;

export const BaseFormInput = styled.input`
  ${styles}
`;

export const BaseFormTextArea = styled.textarea`
  resize: none;
  ${styles}
`;

export const TextInputWrapper = styled(Flex)`
  ${props => props.variant === TEXT_INPUT_VARIANT.GHOST && css`
    > label {
      color: ${props.theme.colors.seaGreen};
    }
  `}
  ${props => props.variant === TEXT_INPUT_VARIANT.GHOST_SECONDARY && css`
    > label {
      font-family: ${props.theme.fonts[FONT_FAMILY.HAILSR_BOOK]};
      color: ${props.theme.colors.black};
    }
  `}
`;

const IconWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 10px);
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-left:  1px solid rgba(24, 33, 47, 0.15);
  margin: 5px 0;
  z-index: 1;
  
  & > button {
    background: transparent;
    cursor: pointer;
    padding: 0;
    border: 0;
  }
`;

const TextInput = ({
  input, meta, label, width, variant, labelColor, type, icon, onIconClick, ...props
}) => (
  <TextInputWrapper width={width} variant={variant} flexDirection="column" style={{ position: 'relative' }}>
    {label && <Label color={labelColor}>{label}</Label>}
    {type !== 'textarea' && <BaseFormInput {...props} {...input} variant={variant} icon={icon} type={type} />}
    {type === 'textarea' && <BaseFormTextArea {...props} {...input} variant={variant} type={type} />}
    {icon && (
      <IconWrapper>
        <button type="submit">
          <Image src={icon} onClick={onIconClick} />
        </button>
      </IconWrapper>
    )}
    <FormError meta={meta} />
  </TextInputWrapper>
);

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  variant: PropTypes.oneOf(Object.values(TEXT_INPUT_VARIANT)),
  labelColor: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  onIconClick: PropTypes.func,
};

TextInput.defaultProps = {
  width: '100%',
  variant: TEXT_INPUT_VARIANT.PRIMARY,
  type: 'text',
};

export default TextInput;

import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Card, Box, Flex, Text,
} from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '../_app/routes';
import Button from '../_common/components/Button';
import HorizontalLine from '../_common/components/HorizontalLine';
import { CARD_VARIANT, FONT_FAMILY, BUTTON_VARIANT } from '../theme';
import InvoicesList from './components/InvoicesList';
import AddonsList from './components/AddonsList';
import AgreementsList from './components/AgreementsList';
import StripeCheckout from './components/StripeCheckout';
import Loader from '../_common/components/Loader';

const GrayContainer = styled(Box)`
  background-color: #dddfe1;
  border-radius: 5px;
  background-image: url('/images/grey-container.svg');
  background-position: bottom;
  background-repeat: no-repeat;
`;

@inject('paymentStore', 'userStore', 'invoicesStore', 'addonsStore', 'stripeStore')
@observer
class Payments extends Component {
  async componentDidMount() {
    const {
      paymentStore, invoicesStore, addonsStore, stripeStore, location,
    } = this.props;

    paymentStore.fetchPayments();
    invoicesStore.fetchInvoices(true);
    addonsStore.fetchAgreements();
    addonsStore.fetchMyAddons();
    stripeStore.getPaymentInfo();

    const params = queryString.parse(location.search);

    if (params.redirect_flow_id) {
      await paymentStore.completeGoCardlessFlow(params.redirect_flow_id, false);
    }

    await paymentStore.startGoCardlessFlow(
      `${process.env.REACT_APP_URL}${APP_ROUTES.PAYMENTS}`,
    );
  }

  onEditPaymentMethod = () => {
    const { paymentStore } = this.props;

    const redirectUrl = get(paymentStore, 'data.redirect_url');

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  _getBannerTitles = (isStripeSetup) => {
    if (!isStripeSetup) {
      return { mainTitle: 'Almost ready!', subtitle: 'Please, setup your payment details' };
    }
    return { mainTitle: 'You are set up!', subtitle: '' };
  };

  render() {
    const {
      invoicesStore: {
        invoices, isLoading, fetchInvoices, error, hasMore,
      },
      addonsStore: {
        agreements: {
          isLoading: agreementsIsLoading,
          data: agreementsData,
          error: agreementsError,
          hasMore: agreementsHasMore,
        },
        addons: {
          isLoading: addonsIsLoading,
          data: addonsData,
          error: addonsError,
          hasMore: addonsHasMore,
        },
        cancelAddon,
        fetchMyAddons,
        fetchAgreements,
      },
      stripeStore: {
        paymentInfo,
      },
    } = this.props;
    const isStripeSetup = get(this.props.userStore, 'profile.space.stripe.customerId');
    return (
      <Box>
        <Text
          fontSize={40}
          color="black"
          fontFamily={FONT_FAMILY.HAILSR_BOLD}
          mb={50}
        >
          Payments & Billing
        </Text>
        <Card p={65} mb={25} variant={CARD_VARIANT.PRIMARY}>
          {paymentInfo.isLoading ? (
            <Loader fullPage={false} />
          ) : (
            <>
              <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} textAlign="center" fontSize={27} color="white" mb={15}>
                {paymentInfo.title}
              </Text>
              <Text fontFamily={FONT_FAMILY.HAILSR_BOOK} fontSize={17} textAlign="center">
                {paymentInfo.subtitle}
              </Text>
            </>
          )}
        </Card>
        <Flex
          justifyContent="center"
          mx={[0, -10, -10]}
          flexDirection={['column', 'row', 'row']}
          mb={25}
        >
          <InvoicesList
            invoices={invoices}
            onLoadMore={fetchInvoices}
            hasMore={hasMore}
            error={error}
            isLoading={isLoading}
          />
          <Card
            variant={CARD_VARIANT.SECONDARY}
            p={25}
            mx={[0, 10, 10]}
            width={[1, 1 / 2, 1 / 2]}
          >
            <Text
              fontFamily={FONT_FAMILY.HAILSR_BOLD}
              color="white"
              mb={25}
              fontSize={24}
            >
              Payment Info
            </Text>
            <HorizontalLine mb={25} />
            <StripeCheckout>
              {({ isLoading: disabled, onClick }) => (
                <Button disabled={disabled} onClick={onClick} style={{ display: 'block', margin: '0 auto' }}>
                  <Text fontSize={24} fontWeight={400} color="seaGreen" mb={25}>
                    {`${isStripeSetup ? 'Edit' : 'Setup'} Your Stripe Payment Details`}
                  </Text>
                </Button>
              )}
            </StripeCheckout>
          </Card>
        </Flex>
        <GrayContainer p={[10, 27]}>
          <Flex width={1} justifyContent="space-between" mb={20}>
            <Text fontSize={24}>Plans</Text>
            <Link to={APP_ROUTES.EXTRAS}>
              <Button style={{ boxShadow: 'none' }} variant={BUTTON_VARIANT.SECONDARY}>Search add-ons</Button>
            </Link>
          </Flex>
          <AddonsList
            addons={addonsData}
            onLoadMore={() => fetchMyAddons(true)}
            hasMore={addonsHasMore}
            cancelAddon={cancelAddon}
            error={addonsError}
            isLoading={addonsIsLoading}
          />
          <Box pt={25} />
          <AgreementsList
            agreements={agreementsData}
            onLoadMore={() => fetchAgreements(true)}
            hasMore={agreementsHasMore}
            error={agreementsError}
            isLoading={agreementsIsLoading}
          />
        </GrayContainer>
      </Box>
    );
  }
}

Payments.propTypes = {
  paymentStore: PropTypes.shape({
    startGoCardlessFlow: PropTypes.func.isRequired,
    completeGoCardlessFlow: PropTypes.func.isRequired,
    fetchPayments: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.object.isRequired,
  }),
  invoicesStore: PropTypes.shape({
    fetchInvoices: PropTypes.array,
    invoices: PropTypes.array,
    isLoading: PropTypes.bool,
    hasMore: PropTypes.bool,
  }),
  userStore: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    isLoading: PropTypes.object.isRequired,
  }),
  addonsStore: PropTypes.shape({
    fetchAgreements: PropTypes.func.isRequired,
    fetchMyAddons: PropTypes.func.isRequired,
    agreements: PropTypes.object,
    addons: PropTypes.object,
    cancelAddon: PropTypes.func,
  }),
  stripeStore: PropTypes.shape({
    getPaymentInfo: PropTypes.func,
    paymentInfo: PropTypes.object,
  }),
  location: PropTypes.object.isRequired,
};

export default Payments;

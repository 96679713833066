import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import styled from 'styled-components';
import Loader from '../_common/components/Loader';

const Wrapper = styled(Box)`
  word-break: break-word;
  white-space: pre-wrap;
  -moz-white-space: pre-wrap;
  ol,
  ul {
    list-style: none;
  }
  h1,
  h2,
  h3,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-family: HalisR;
  }
  h1,
  h2,
  h3 {
    font-family: HalisR-Medium;
    margin-bottom: 12px;
    line-height: 1;
  }
  h3 {
    font-size: 20px;
  }
  h2 {
    font-size: 24px;
  }
  h1 {
    font-size: 32px;
  }
  ol,
  ul {
    li:not(.ql-direction-rtl)::before {
      top: 0;
      position: relative;
      font-size: 16px;
      margin-right: 0.5rem;
      width: auto;
    }
  }
  li.ql-indent-1 {
    padding-left: 30px;
  }
  li.ql-indent-2 {
    padding-left: 45px;
  }
  li.ql-indent-3 {
    padding-left: 60px;
  }
  li.ql-indent-4 {
    padding-left: 75px;
  }

  ol li {
    counter-increment: list-0;
  }
  ol li.ql-indent-1 {
    counter-increment: list-1;
  }
  ol li.ql-indent-2 {
    counter-increment: list-2;
  }
  ol li.ql-indent-3 {
    counter-increment: list-3;
  }
  ol li.ql-indent-4 {
    counter-increment: list-4;
  }
  ul li:before {
    content: '•';
  }
  ol li:before {
    content: counter(list-0, decimal) '. ';
  }
  ol li.ql-indent-1:before {
    content: counter(list-0, decimal) '. ' counter(list-1, decimal) '. ';
  }
  ol li.ql-indent-2:before {
    content: counter(list-0, decimal) '. ' counter(list-1, decimal) '. ' counter(list-2, decimal) '. ';
  }
  ol li.ql-indent-3:before {
    content: counter(list-0, decimal) '. ' counter(list-1, decimal) '. ' counter(list-2, decimal) '. '
      counter(list-3, decimal) '. ';
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }
`;

@inject('supportStore')
@observer
class Policies extends Component {
  componentDidMount() {
    const { type } = this.props.match.params;
    this.props.supportStore.fetchPolicy({ type });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { type } = this.props.match.params;
      this.props.supportStore.fetchPolicy({ type });
    }
  }

  render() {
    const { isLoading, data: { title, description } = {} } = this.props.supportStore.policy;
    if (isLoading) {
      return <Loader py={40} fullPage={false} />;
    }
    return (
      <Wrapper>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Wrapper>
    );
  }
}

Policies.wrappedComponent.propTypes = {
  supportStore: PropTypes.shape({
    fetchPolicy: PropTypes.func,
    policy: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.object,
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  location: PropTypes.object,
};

export default Policies;

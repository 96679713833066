import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Image, Text } from 'rebass';
import { FONT_FAMILY } from '../theme';

const SignUpSteps = ({ step, color, mt }) => (
  <Flex justifyContent="center" flexDirection="column" mt={mt} style={{ position: 'relative' }}>
    <Flex justifyContent="center" mb={15}>
      <Image src={`/images/sign-up-steps-${step}.png`} />
    </Flex>
    <Flex justifyContent="center">
      <Flex width={381} justifyContent="space-between">
        <Text fontFamily={FONT_FAMILY.HAILSR_BOOK} fontSize={13} color={color || 'white'} textAlign="center" style={{ width: 168 }}>
          Set up
          <br />
          payment method
        </Text>
        <Text fontFamily={FONT_FAMILY.HAILSR_BOOK} fontSize={13} color={color || 'white'} textAlign="center" style={{ width: 168 }}>
          Add your
          <br />
          team members
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

SignUpSteps.propTypes = {
  step: PropTypes.oneOf([1, 2]),
  color: PropTypes.string,
  mt: PropTypes.number,
};

SignUpSteps.defaultProps = {
  mt: 50,
};

export default SignUpSteps;

import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Text } from 'rebass';
import { observable } from 'mobx';
import BaseLayout from '../_common/layouts/BaseLayout';
import MembersInvitationForm from '../teamManagement/MembersInvitationForm';
import MembersList from '../teamManagement/MembersList';
import { colors, FONT_FAMILY } from '../theme';
import SignUpSteps from './SignUpSteps';
import InvitationSuccessModal from '../teamManagement/InvitationSuccessModal';

@inject('userStore')
@observer
class YourTeam extends Component {
  @observable isOpen = false;

  render() {
    const { userStore: { inviteMembers, invitedMembers, existingMembers } } = this.props;
    return (
      <Box>
        <BaseLayout pt={100} pb={75}>
          <SignUpSteps mt={0} step={2} color="seaGreen" />
          <Text
            fontSize={40}
            color="black"
            fontFamily={FONT_FAMILY.HAILSR_BOLD}
            mb={50}
          >
            Your team
          </Text>
          <MembersList isSearchEnabled={false} />
        </BaseLayout>
        <BaseLayout py={75} bgColor={colors.black}>
          <Text
            fontSize={28}
            color="seaGreen"
            fontFamily={FONT_FAMILY.HAILSR_BOLD}
            mb={25}
          >
            Are we missing anyone? Add them here:
          </Text>
          <MembersInvitationForm onSubmit={values => inviteMembers(values, () => { this.isOpen = true; })} />
          {this.isOpen && <InvitationSuccessModal invitedMembers={invitedMembers} existingMembers={existingMembers} onClose={() => { this.isOpen = false; }} />}
        </BaseLayout>
      </Box>
    );
  }
}

YourTeam.wrappedComponent.propTypes = {
  userStore: PropTypes.shape({
    inviteMembers: PropTypes.func.isRequired,
  }),
};

export default YourTeam;

import PropTypes from 'prop-types';
import React from 'react';
import {
  Flex,
  Image,
  Box,
} from 'rebass';
import styled from 'styled-components';

const CheckboxInput = styled('input')`
  position: absolute;
  visibility: hidden;
`;

const Checkbox = ({
  label, input, id, ...props
}) => (
  <Flex sx={{ position: 'relative' }}>
    <Flex as="label" htmlFor={id} style={{ cursor: 'pointer', textAlign: 'center', ...props.style }} {...props}>
      <Box width={30}>
        <Image height={20} src={input.checked ? '/images/icons/tick-icon.png' : '/images/icons/x-icon.png'} />
      </Box>
      {label}
    </Flex>
    <CheckboxInput {...input} id={id} type="checkbox" />
  </Flex>
);

Checkbox.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  afterChange: PropTypes.func,
  id: PropTypes.string,
};

export default Checkbox;

import React, { Component } from 'react';
import {
  Router, Switch, Redirect, Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { APP_ROUTES } from './_app/routes';
import NavBar, {
  NAV_BAR_VARIANT,
} from './_common/components/navigation/NavBar';
import ForgotPassword from './forgotPassword/ForgotPassword';
import ScrollToTop from './ScrollToTop';
import AppRoute from './_common/AppRoute';
import SignInLayout from './_common/layouts/SignInLayout';
import BaseLayout from './_common/layouts/BaseLayout';
import { history } from './_app/history';
import SignInWithToken from './signIn/SignInWithToken';
import ResetPassword from './resetPassword/ResetPassword';
import StripeSignUp from './signUp/Stripe';
import GoCardlessSignUp from './signUp/GoCardless';
import SignIn from './signIn/SignIn';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Dashboard from './dashboard/Dashboard';
import Payments from './payments/Payments';
import YourTeam from './signUp/YourTeam';
import TeamManagement from './teamManagement/TeamManagement';
import Support from './support/Support';
import Account from './account/Account';
import MemberWelcome from './memberWelcome/MemberWelcome';
import { redirectAuthenticatedUser } from './hoc/auth';
import { initializeGAPageView } from './_app/googleAnalytics';
import Addons from './addons/Addons';
import 'react-datepicker/dist/react-datepicker.css';
import Policies from './policies/Policies';

class App extends Component {
  componentDidMount() {
    initializeGAPageView(history);
  }
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <AppRoute
              exact
              path={APP_ROUTES.SIGN_IN_WITH_TOKEN}
              component={SignInWithToken}
              layout={SignInLayout}
              navBar={() => null}
            />
            <AppRoute
              exact
              path={APP_ROUTES.SIGN_IN}
              component={redirectAuthenticatedUser(SignIn)}
              layout={SignInLayout}
              navBar={() => null}
            />
            <AppRoute
              exact
              path={APP_ROUTES.FORGOT_PASSWORD}
              component={ForgotPassword}
              layout={SignInLayout}
              navBar={() => null}
            />
            <AppRoute
              isPrivate
              path={APP_ROUTES.GO_CARDLESS}
              component={GoCardlessSignUp}
              layout={SignInLayout}
              navBar={() => null}
            />
            <AppRoute
              isPrivate
              path={APP_ROUTES.STRIPE}
              component={StripeSignUp}
              layout={SignInLayout}
              navBar={() => null}
            />
            <AppRoute
              exact
              isPrivate
              path={APP_ROUTES.YOUR_TEAM}
              component={YourTeam}
              layout={({ children }) => children}
              navBar={() => <NavBar variant={NAV_BAR_VARIANT.WHITE} />}
            />
            <AppRoute
              exact
              path={APP_ROUTES.RESET_PASSWORD}
              layout={SignInLayout}
              component={ResetPassword}
              navBar={() => null}
            />
            <AppRoute
              exact
              isPrivate
              path={APP_ROUTES.DASHBOARD}
              component={Dashboard}
              layout={BaseLayout}
            />
            <AppRoute
              exact
              isPrivate
              path={APP_ROUTES.PAYMENTS}
              component={Payments}
              layout={BaseLayout}
              navBar={() => <NavBar variant={NAV_BAR_VARIANT.WHITE} />}
            />
            <AppRoute
              exact
              isPrivate
              path={APP_ROUTES.TEAM_MANAGEMENT}
              component={TeamManagement}
              layout={({ children }) => children}
              navBar={() => <NavBar variant={NAV_BAR_VARIANT.WHITE} />}
            />
            <AppRoute
              exact
              isPrivate
              path={APP_ROUTES.EXTRAS}
              component={Addons}
              layout={({ children }) => children}
              navBar={() => <NavBar variant={NAV_BAR_VARIANT.GREEN} />}
            />
            <AppRoute
              exact
              isPrivate
              path={APP_ROUTES.SUPPORT}
              component={Support}
              layout={BaseLayout}
              navBar={() => <NavBar variant={NAV_BAR_VARIANT.WHITE} />}
            />
            <AppRoute
              exact
              isPrivate
              path={APP_ROUTES.ACCOUNT}
              component={Account}
              layout={({ children }) => children}
              navBar={() => <NavBar variant={NAV_BAR_VARIANT.WHITE} />}
            />

            <AppRoute
              exact
              path={APP_ROUTES.MEMBER_WELCOME}
              component={MemberWelcome}
              layout={SignInLayout}
              navBar={() => null}
            />

            <AppRoute
              exact
              path={APP_ROUTES.ROOT}
              isPrivate
              component={Dashboard}
              bgColor="#18212f"
            />

            <AppRoute
              exact
              path={APP_ROUTES.POLICIES}
              isPrivate
              component={Policies}
              bgColor="#fff"
            />
            <Route path="*" render={() => <Redirect to={APP_ROUTES.ROOT} />} />
          </Switch>
        </ScrollToTop>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          toastClassName="yellowbg"
        />
      </Router>
    );
  }
}

export default App;

import { Flex } from 'rebass';
import styled from 'styled-components';

const HorizontalLine = styled(Flex)`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${props => props.theme.colors[props.color] || '#979797'};
  opacity: ${props => props.opacity || 1};
`;

export default HorizontalLine;

import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'rebass';
import BaseLayout from './BaseLayout';

const SignInLayout = ({ children, ...props }) => (
  <BaseLayout {...props} bgColor="#25b3b0" pt={50}>
    <Image height={54} src="/images/logo-fully-white.svg" />
    {children}
  </BaseLayout>
);

SignInLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignInLayout;

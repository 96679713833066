export const FONT_FAMILY = {
  VENEER_CLEAN: 'VeneerClean',
  HAILSR_BOLD: 'HalisR-Bold',
  HAILSR_BOOK: 'HalisR-Book',
  HAILSR_MEDIUM: 'HalisR-Medium',
};

export const colors = {
  seaGreen: '#25b3b0',
  seaGreenLight: '#bde8e7',
  mainBg: '#F0F2FA',
  primaryText: '#212840',
  secondaryBg: '#212840',
  secondaryText: '#f8b674',
  border: '#bfbfc6',
  main: '#000',
  error: '#fb6868',
  mainOrange: '#fabe79',
  secondaryOrange: '#FFD98E',
  buttonSecondary: '#b1bfe3',
  black: '#18212f',
  red: '#fb6868',
  white: '#fff',
};

export const CARD_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  REVERSE: 'reverse',
};

export default {
  breakpoints: ['40em', '56em', '72em'],
  colors,
  fonts: {
    [FONT_FAMILY.VENEER_CLEAN]: 'VeneerClean',
    [FONT_FAMILY.HAILSR_BOLD]: 'HalisR-Bold',
    [FONT_FAMILY.HAILSR_BOOK]: 'HalisR-Book',
    [FONT_FAMILY.HAILSR_MEDIUM]: 'HalisR-Medium',
  },
  buttons: {
    [BUTTON_VARIANT.PRIMARY]: {
      color: '#fff',
      backgroundColor: colors.black,
    },
    [BUTTON_VARIANT.SECONDARY]: {
      color: '#fff',
      backgroundColor: colors.seaGreen,
      ':hover': {
        backgroundColor: '#000',
      },
    },
    [BUTTON_VARIANT.REVERSE]: {
      color: colors.black,
      backgroundColor: '#fff',
    },
  },
  cards: {
    [CARD_VARIANT.PRIMARY]: {
      borderRadius: 5,
      backgroundColor: colors.seaGreen,
      backgroundImage: 'url("/images/card-background.png")',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
    [CARD_VARIANT.SECONDARY]: {
      borderRadius: 5,
      backgroundColor: colors.black,
    },
    [CARD_VARIANT.TERTIARY]: {
      borderRadius: 5,
      backgroundColor: 'white',
      border: `1px solid ${colors.black}`,
    },
  },
};

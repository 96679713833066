import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import Button from '../_common/components/Button';
import { Error } from '../_common/components/forms/FormError';
import { FONT_FAMILY } from '../theme';
import SignUpSteps from './SignUpSteps';

@inject('paymentStore')
@withRouter
@observer
class GoCardless extends Component {
  async componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.redirect_flow_id) {
      return this.props.paymentStore.completeGoCardlessFlow(params.redirect_flow_id, true);
    }
    this.props.paymentStore.startGoCardlessFlow();
  }

  onClick = (redirectUrl) => {
    window.location.href = redirectUrl;
  };

  render() {
    const {
      paymentStore: {
        data,
        error,
      },
    } = this.props;
    const redirectUrl = get(data, 'redirect_url');
    return (
      <Fragment>
        <SignUpSteps step={1} />
        <Text
          fontSize={35}
          fontFamily={FONT_FAMILY.HAILSR_BOOK}
          textAlign="center"
          color="black"
          lineHeight={1}
          my={40}
        >
          GoCardless
        </Text>
        {error && <Error textAlign="center">{error}</Error>}
        <Flex width={1} flexWrap="wrap" flexDirection="column" alignItems="center">
          {redirectUrl && (
            <Button onClick={() => this.onClick(redirectUrl)}>
              Set up payment method
            </Button>
          )}
        </Flex>
      </Fragment>
    );
  }
}

GoCardless.propTypes = {
  paymentStore: PropTypes.shape({
    startGoCardlessFlow: PropTypes.func.isRequired,
    completeGoCardlessFlow: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.object.isRequired,
  }),
  location: PropTypes.object.isRequired,
};

export default GoCardless;

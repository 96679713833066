import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { StripeProvider, Elements } from 'react-stripe-elements';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

@observer
class StripeWrapper extends Component {
  @observable stripe = null;

  componentDidMount() {
    if (window.Stripe) {
      this.stripe = window.Stripe(REACT_APP_STRIPE_PUBLIC_KEY);
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.stripe = window.Stripe(REACT_APP_STRIPE_PUBLIC_KEY);
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.stripe}>
        <Elements>{this.props.children}</Elements>
      </StripeProvider>
    );
  }
}

StripeWrapper.propTypes = {
  children: PropTypes.node,
};

export default StripeWrapper;

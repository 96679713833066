import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';
import toPairs from 'lodash/toPairs';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Box, Card, Flex, Image, Text,
} from 'rebass';
import CollapsiblePanel from '../_common/components/CollapsiblePanel';
import HorizontalLine from '../_common/components/HorizontalLine';
import { CARD_VARIANT, FONT_FAMILY } from '../theme';
import FeedbackForm from './FeedbackForm';
import SearchForm from './SearchForm';

@inject('supportStore')
@observer
class Support extends Component {
  state = {
    isOpen: false,
    isFeedbackSent: false,
  };

  componentDidMount() {
    this.props.supportStore.fetchSupport();
  }

  onSubmit = values => this.props.supportStore.sendFeedback(values, () => this.setState({ isFeedbackSent: true }));

  getLeftFAQs = () => take(toPairs(this.props.supportStore.faqs), Math.ceil(this.props.supportStore.totalFaqs / 2));

  isLeftColumnRendered = () => take(toPairs(this.props.supportStore.faqs), Math.ceil(this.props.supportStore.totalFaqs / 2)).filter(([, faqs]) => !isEmpty(faqs)).length > 0;

  getRightFAQs = () => takeRight(toPairs(this.props.supportStore.faqs), Math.floor(this.props.supportStore.totalFaqs / 2));

  render() {
    const { supportStore } = this.props;
    const { isFeedbackSent } = this.state;
    return (
      <Box>
        <Text
          fontSize={40}
          color="seaGreen"
          fontFamily={FONT_FAMILY.HAILSR_BOLD}
          mb={50}
        >
          Report issues and share feedback
        </Text>
        <Text fontSize={35} color="black" mb={50}>
          How can we help today?
        </Text>
        <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} fontSize={27}>
          I need help with:
        </Text>
        <HorizontalLine my={20} color="black" opacity={0.15} />
        <SearchForm
          onSubmit={(values) => {
            this.setState({ isOpen: !!values.filter });
            return supportStore.fetchSupport(values.filter);
          }}
        />
        <Flex flexDirection={['column', 'row', 'row']} mx={-10} mt={20}>
          {this.isLeftColumnRendered() && (
            <Flex flexDirection="column" width={[1, 1 / 2, 1 / 2]} px={10}>
              {this.getLeftFAQs().map(
                ([section, faqs]) => {
                  if (isEmpty(faqs)) {
                    return null;
                  }
                  return (
                    <CollapsiblePanel
                      initialIsOpen={this.state.isOpen}
                      key={section}
                      title={section}
                    >
                      {faqs.map(({ q, a }, index) => (
                        <Flex key={index} flexDirection="column">
                          <Text mb={10}>{`Q: ${q}`}</Text>
                          <Text fontFamily={FONT_FAMILY.HAILSR_BOOK}>{`A: ${a}`}</Text>
                          {index !== faqs.length - 1 && (
                            <HorizontalLine my={20} />
                          )}
                        </Flex>
                      ))}
                    </CollapsiblePanel>
                  );
                },
              )}
            </Flex>
          )}
          <Flex flexDirection="column" width={[1, 1 / 2, 1 / 2]} px={10}>
            {this.getRightFAQs().map(
              ([section, faqs]) => {
                if (isEmpty(faqs)) {
                  return null;
                }
                return (
                  <CollapsiblePanel
                    initialIsOpen={this.state.isOpen}
                    key={section}
                    title={section}
                  >
                    {faqs.map(({ q, a }, index) => (
                      <Flex key={index} flexDirection="column">
                        <Text mb={10}>{`Q: ${q}`}</Text>
                        <Text fontFamily={FONT_FAMILY.HAILSR_BOOK}>{`A: ${a}`}</Text>
                        {index !== faqs.length - 1 && (
                          <HorizontalLine my={20} />
                        )}
                      </Flex>
                    ))}
                  </CollapsiblePanel>
                );
              },
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="row" flexWrap="wrap">
          <Box width={[1, 1 / 2, 1 / 2]}>
            <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} fontSize={27} mt={50}>
              Help us with your feedback
            </Text>
            <HorizontalLine my={25} color="black" opacity={0.15} />
            <Card
              variant={CARD_VARIANT.SECONDARY}
              p={25}
              style={{ height: 332 }}
            >
              {isFeedbackSent ? (
                <Flex
                  py={70}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image mb={40} src="/images/icons/tick-icon.png" />
                  <Text
                    textAlign="center"
                    fontSize={24}
                    fontFamily={FONT_FAMILY.HAILSR_BOLD}
                    color="seaGreen"
                  >
                    Congratulations
                  </Text>
                  <Text textAlign="center" fontSize={24} color="white">
                    Your feedback is all sent!
                  </Text>
                </Flex>
              ) : (
                <FeedbackForm onSubmit={this.onSubmit} />
              )}
            </Card>
          </Box>
          <Flex
            width={[1, 1 / 2, 1 / 2]}
            alignItems="flex-end"
            justifyContent="flex-end"
            mt={2}
          />
        </Flex>
      </Box>
    );
  }
}

Support.wrappedComponent.propTypes = {
  supportStore: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
    fetchSupport: PropTypes.func.isRequired,
    faqs: PropTypes.object.isRequired,
    sendFeedback: PropTypes.func,
    totalFaqs: PropTypes.number,
  }),
};

export default Support;

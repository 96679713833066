import includes from 'lodash/includes';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Box, Flex, Image,
} from 'rebass';
import styled, { css } from 'styled-components';
import { APP_ROUTES } from '../../../_app/routes';
import Button from '../Button';
import Menu from './Menu';

export const NAV_BAR_VARIANT = {
  BLACK: 'dark',
  WHITE: 'dark',
  GREEN: 'green',
};

const Wrapper = styled(Box)`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 25;

  ${props => props.variant === NAV_BAR_VARIANT.WHITE && css`
    background-color: white;
  `}

  ${props => props.variant === NAV_BAR_VARIANT.GREEN && css`
    background-color: ${props.theme.colors.seaGreen};
  `}
`;

const ROUTES_WITHOUT_LOGIN_BTN = [APP_ROUTES.SIGN_IN, APP_ROUTES.RESET_PASSWORD];

@withRouter
@inject('authStore', 'userStore')
@observer
class NavBar extends Component {
  componentDidMount() {
    this.props.userStore.fetchProfile();
  }

  getLogo = () => {
    const { variant } = this.props;
    switch (variant) {
      case NAV_BAR_VARIANT.WHITE: {
        return '/images/logo-black.svg';
      }
      case NAV_BAR_VARIANT.GREEN: {
        return '/images/logo-fully-white.svg';
      }
      default: {
        return '/images/logo-white.svg';
      }
    }
  };

  render() {
    const {
      match: { path },
      authStore: {
        isAuthenticated,
        logout,
      },
      variant,
    } = this.props;
    return (
      <>
        <Wrapper variant={variant} p={[15, 15, 30]}>
          <Flex width={1} justifyContent="space-between">
            <Link style={{ height: 35 }} to={APP_ROUTES.ROOT}>
              <Image src={this.getLogo()} />
            </Link>
            <Flex alignItems="center">
              {isAuthenticated && <Menu logout={logout} hamburgerColor={variant === NAV_BAR_VARIANT.GREEN && '#fff'} />}
              {!isAuthenticated && !includes(ROUTES_WITHOUT_LOGIN_BTN, path) && (
                <Link to={APP_ROUTES.SIGN_IN}>
                  <Button
                    ml={[0, 20, 20]}
                    mr={[0, 40, 40]}
                    py="8px"
                    width={[100, 160, 160]}
                    minWidth={100}
                  >
                    Sign in
                  </Button>
                </Link>
              )}
            </Flex>
          </Flex>
        </Wrapper>
      </>
    );
  }
}

NavBar.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
  userStore: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    fetchProfile: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.object,
  variant: PropTypes.oneOf([NAV_BAR_VARIANT.BLACK, NAV_BAR_VARIANT.WHITE, NAV_BAR_VARIANT.GREEN]),
};

export default NavBar;

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import styled from 'styled-components';
import Button from '../_common/components/Button';
import { BUTTON_VARIANT, FONT_FAMILY } from '../theme';

const Wrapper = styled(Box)`
  border-radius: 5px;
  background-color: white;
`;

const ImageWrapper = styled(Flex)`
  border-bottom: 1px solid rgba(37, 179, 176, 0.3);
`;

const DescriptionText = styled(Text)`
  min-height: 63px;
  
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    height: 66px;
  }
`;

const DashboardItem = ({
  iconUrl,
  title,
  description,
  link,
}) => (
  <Wrapper width={1} px={20} py={25}>
    <ImageWrapper width={1} pb={25} mb={25} alignItems="center">
      <Image src={iconUrl} style={{ height: 72 }} mr={16} />
      <Text fontSize={23}>{title}</Text>
    </ImageWrapper>
    <DescriptionText fontSize={16} mb={10} color="#6f6f6f" fontFamily={FONT_FAMILY.HAILSR_BOOK}>{description}</DescriptionText>
    <Link to={link}>
      <Button variant={BUTTON_VARIANT.SECONDARY} width={[1, 1, 1, 0.7]}>View</Button>
    </Link>
  </Wrapper>
);

DashboardItem.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default DashboardItem;

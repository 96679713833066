import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Text, Image, Box, Flex, Link,
} from 'rebass';
import queryString from 'query-string';

class MemberWelcome extends Component {
  render() {
    const { name = '' } = queryString.parse(this.props.location.search);
    return (
      <Flex mt={[0, 80, 80]} flexDirection={['column', 'column', 'row']} alignItems={['center', 'center', 'space-between']} justifyContent="space-between">
        <Image mt={[20, 0, 0]} src="/images/iphone.png" width={[1, 360, 360]} order={[1, 0, 0]} />
        <Box ml={[0, 0, 50]} order={[0, 0, 1]}>
          <Text
            mt={50}
            fontSize={[40, 70, 70]}
            textAlign="center"
            color="white"
            lineHeight={1}
            mb={20}
          >
            {`Get the app, ${name}`}
          </Text>
          <Text fontSize={30} mt={80} textAlign="center">
           Download the app via:
          </Text>
          <Flex mt={40} width={1} justifyContent="center">
            <Link href="https://apps.apple.com/us/app/yours/id1472189159?ls=1">
              <Image
                src="/images/app-store-button.png"
                width={170}
                mt="2px"
                pr={10}
              />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=life.work.yours">
              <Image src="/images/google-play-button.png" width={160} pl={10} />
            </Link>
          </Flex>
        </Box>
      </Flex>
    );
  }
}

MemberWelcome.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MemberWelcome;

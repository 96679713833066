import PropTypes from 'prop-types';
import React from 'react';
import { Box, Text } from 'rebass';
import styled from 'styled-components';

export const Error = styled(Text)`
  display: block;
  font-style: italic;
  color: ${props => props.theme.colors.error};
  font-size: 14px;
`;

const Relative = styled(Box)`
  position: relative;
`;

export const AbsolutePositionedErrorsForm = styled.form`
  .form-error {
    position: absolute;
  }
`;

const FormError = ({ meta, ...props }) => (
  <Relative>
    {(meta.touched || meta.submitFailed) && (meta.error || meta.submitError) && (
      <Error {...props} className="form-error">
        {meta.error || meta.submitError[0]}
      </Error>
    )}
  </Relative>
);

FormError.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormError;

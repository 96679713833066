import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';
import routerStore from './routerStore';
import userStore from './userStore';

export class StripeStore {
  @observable isLoading = false;

  @observable paymentInfo = {
    isLoading: false,
  };

  @action fetchSessionToken = async (redirectToCheckout) => {
    this.isLoading = true;
    try {
      const {
        data: { id: sessionId },
      } = await API.post(API_ROUTES.STRIPE_SESSION_TOKEN, { domain: window.location.origin });
      redirectToCheckout({ sessionId });
    } catch (e) {
      this._errorHandler(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action postSessionToken = async (sessionId) => {
    this.isLoading = true;
    try {
      await API.post(`${API_ROUTES.STRIPE_SESSION_TOKEN}/${sessionId}`);
      await userStore.fetchProfile();
      toast.info('Thank you. You have successfully setup your payment.');
    } catch (e) {
      this._errorHandler(e);
    } finally {
      routerStore.push(APP_ROUTES.PAYMENTS);
      this.isLoading = false;
    }
  };

  @action getPaymentInfo = async () => {
    this.paymentInfo.isLoading = true;
    try {
      const { data } = await API(API_ROUTES.STRIPE_PAYMENT_INFO);
      this.paymentInfo = {
        isLoading: false,
        ...data,
      };
    } catch (e) {
      this.paymentInfo = {
        isLoading: false,
        title: 'Something went wrong.',
        subtitle: 'Could not fetch payment info.',
      };
    }
  };

  _errorHandler = (e) => {
    toast.error(e.message);
  }
}

export default new StripeStore();

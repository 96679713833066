import { action, extendObservable } from 'mobx';
import { FORM_ERROR } from 'final-form';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import omit from 'lodash/omit';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import auth from './authStore';
import { TOKEN_KEY } from '../_app/constants';

const initialState = {
  isLoading: false,
  profile: {},
  error: null,
  existingMembers: [],
  invitedMembers: [],
};

const { REACT_APP_ENV } = process.env;

export class UserStore {
  constructor() {
    extendObservable(this, initialState);
  }
  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action
  fetchProfile = async () => {
    if (!auth.isAuthenticated) return;
    this.isLoading = true;
    try {
      const { data } = await API(API_ROUTES.PROFILE);
      this.profile = data.data;
      if (REACT_APP_ENV === 'production') {
        const userSubdomain = get(this.profile, 'space.subdomain', 'app.yours');
        const currentSubdomain = get(window.location.host.split('.work.life'), '[0]');
        if (currentSubdomain !== userSubdomain) {
          const token = localStorage.getItem(TOKEN_KEY);
          window.location.replace(`https://${userSubdomain}.work.life?token=${token}&redirect=`);
        }
      }
    } catch (e) {
      toast.error(e.error || e.message || "Couldn't load profile.");
    } finally {
      this.isLoading = false;
    }
  };

  @action updateProfile = async (profileData) => {
    this.isLoading = true;
    try {
      const {
        data: { profile },
      } = await API.patch(API_ROUTES.PROFILE, omit(profileData, ['avatar']));
      this.profile = { ...this.profile, ...profile };
      toast.info('Profile updated.');
    } catch (e) {
      if (e.errors) {
        return e.errors;
      }
      return { [FORM_ERROR]: get(e, 'message') };
    } finally {
      this.isLoading = false;
    }
  };

  @action inviteMembers = async (data, cb) => {
    try {
      const { data: { data: { existingMembers, invitedMembers } } } = await API.post('/members/invite', data);
      this.existingMembers = existingMembers;
      this.invitedMembers = invitedMembers;
      cb();
      if (typeof cb === 'function') cb();
    } catch (e) {
      if (e.errors) {
        return e.errors;
      }
      return { [FORM_ERROR]: get(e, 'message') };
    }
  };
}

export default new UserStore();

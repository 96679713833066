import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import styled from 'styled-components';
import Loader from '../_common/components/Loader';
import { FONT_FAMILY } from '../theme';
import DeleteUserModal from './DeleteUserModal';
import SearchForm from './SearchForm';
import UserDetailsModal from './UserDetailsModal';

const ListContainer = styled(Box)`
  height: 400px;
  border-radius: 5px;
  border: solid 1px ${props => props.theme.colors.black};
  margin-top: 25px;
  padding: 25px;
  overflow: auto;
`;

const Row = styled(Flex)`
  border-bottom: 1px solid rgba(24, 33, 47, 0.15);

  &:last-of-type {
    border-bottom: 0 none;
  }
  @media (max-width: 750px) {
    width: 600px;
  }
`;

@inject('membersStore')
@observer
class MembersList extends Component {
  state = {
    isUpdateModalOpened: false,
    isDeleteModalOpened: false,
    member: {},
  };

  async componentDidMount() {
    await this.props.membersStore.fetchMembers();
  }

  onDelete = async () => {
    const { member } = this.state;
    const { deleteMember } = this.props.membersStore;
    await deleteMember(member._id);
    this.setState({ isDeleteModalOpened: false });
  };

  render() {
    const {
      membersStore: {
        fetchMembers,
        isLoading,
        members,
      },
      isSearchEnabled,
    } = this.props;
    return (
      <Flex flexDirection="column" mb={25}>
        {isSearchEnabled && <SearchForm onSubmit={fetchMembers} />}
        <ListContainer>
          {isLoading && <Loader />}
          <Row pb={15}>
            <Box width={1 / 2} pr={10}>
              <Text color="seaGreen" fontFamily={FONT_FAMILY.HAILSR_BOOK}>Email</Text>
            </Box>
            <Box width={1 / 2} px={10}>
              <Text color="seaGreen" fontFamily={FONT_FAMILY.HAILSR_BOOK}>Name</Text>
            </Box>
            <Box width={300} px={10}>
              <Text color="seaGreen" fontFamily={FONT_FAMILY.HAILSR_BOOK}>Card Assignments</Text>
            </Box>
            <Box width={300} px={10}>
              <Text color="seaGreen" fontFamily={FONT_FAMILY.HAILSR_BOOK}>Card Number</Text>
            </Box>
            <Flex width={150} pl={10} />
          </Row>
          {members.map(member => (
            <Row py={15} key={member._id}>
              <Box width={1 / 2} pr={10}>
                <Text style={{ wordBreak: 'break-all' }} color="seaGreen">{member.email}</Text>
              </Box>
              <Box width={1 / 2} px={10}>
                <Text style={{ wordBreak: 'break-all' }} width={1}>{`${member.firstName} ${member.lastName}`}</Text>
              </Box>
              <Box width={300} px={10}>
                <Text width={1}>{member.cardNumber ? 'Card assigned' : 'No card assigned'}</Text>
              </Box>
              <Box width={300} px={10}>
                <Text width={1}>{member.cardNumber || '-'}</Text>
              </Box>
              <Flex width={150} pl={10} alignItems="center">
                <Image
                  mr={20}
                  style={{ cursor: 'pointer' }}
                  src="/images/icons/edit-icon.png"
                  onClick={() => this.setState({
                    isUpdateModalOpened: true,
                    member,
                  })}
                />
                <Image
                  style={{ cursor: 'pointer' }}
                  src="/images/icons/x-icon.png"
                  onClick={() => this.setState({
                    isDeleteModalOpened: true,
                    member,
                  })}
                />
              </Flex>
            </Row>
          ))}
          {this.state.isUpdateModalOpened && (
            <UserDetailsModal
              onClose={() => { this.setState({ isUpdateModalOpened: false }); }}
              member={this.state.member}
            />
          )}
          {this.state.isDeleteModalOpened && (
            <DeleteUserModal
              onConfirm={this.onDelete}
              onCancel={() => this.setState({ isDeleteModalOpened: false })}
              email={this.state.member.email}
            />
          )}
          {isEmpty(members) && !isLoading && (
            <Text py={15}>No results.</Text>
          )}
        </ListContainer>
      </Flex>
    );
  }
}

MembersList.wrappedComponent.propTypes = {
  membersStore: PropTypes.shape({
    fetchMembers: PropTypes.func.isRequired,
    deleteMember: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    members: PropTypes.array.isRequired,
  }),
  isSearchEnabled: PropTypes.bool,
};

MembersList.defaultProps = {
  isSearchEnabled: true,
};

export default MembersList;

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  Card, Flex, Text, Link,
} from 'rebass';
import moment from 'moment';
import HorizontalLine from '../../_common/components/HorizontalLine';
import { CARD_VARIANT, FONT_FAMILY } from '../../theme';
import Loader from '../../_common/components/Loader';

const AgreementsList = ({
  agreements, onLoadMore, hasMore, error, isLoading,
}) => (
  <Card variant={CARD_VARIANT.TERTIARY} p={25} width={1} mb={[25, 0, 0]} style={{ border: 'none' }}>
    <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} mb={25} fontSize={24}>
      Agreements
    </Text>
    <HorizontalLine mb={25} />
    {agreements.map((agreement, idx) => (
      <Flex py={10} key={`agreement-item-${idx}`} flexWrap="wrap">
        <Text fontFamily={FONT_FAMILY.HAILSR_BOOK} fontSize={17} width={[1, 1 / 3, 1 / 4]}>
          {moment(agreement.createdAt).format('DD MMMM YYYY')}
        </Text>
        <Flex width={[1, 2 / 3, 3 / 4]} justifyContent="space-between" flexWrap="wrap">
          <Text
            fontFamily={FONT_FAMILY.HAILSR_BOOK}
            fontSize={17}
            fontWeight="700"
          >
            {agreement.name}
          </Text>
          <Link
            color="black"
            target="_blank"
            href={agreement.link}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            fontFamily={FONT_FAMILY.HAILSR_BOOK}
            fontSize={17}
          >
            View Agreement
          </Link>
        </Flex>
      </Flex>
    ))}
    {isLoading && <Loader fullPage={false} />}
    {isEmpty(agreements) && !isLoading && (
      <Text mb={45} fontSize={20}>
        No agreements found
      </Text>
    )}
    <HorizontalLine my={25} />
    {!error && !isEmpty(agreements) && hasMore && (
      <Text fontSize={17} textAlign="center" onClick={() => onLoadMore()} style={{ cursor: 'pointer' }}>
        Older
      </Text>
    )}
  </Card>
);

AgreementsList.propTypes = {
  agreements: PropTypes.array,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
};

export default AgreementsList;

export const APP_ROUTES = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  SIGN_IN_WITH_TOKEN: '/sign-in/:token',
  PAYMENTS: '/payments',
  TEAM_MANAGEMENT: '/team-management',
  SUPPORT: '/support',
  ACCOUNT: '/account',
  DASHBOARD: '/dashboard',
  RESET_PASSWORD: '/reset-password/:token',
  YOUR_TEAM: '/your-team',
  GO_CARDLESS: '/go-cardless',
  STRIPE: '/stripe',
  MEMBER_WELCOME: '/welcome',
  EXTRAS: '/extras',
  POLICIES: '/policies/:type',
  TERMS_AND_CODITIONS: '/policies/terms-and-conditions',
  CHECKOUT: '/checkout',
};

export const API_ROUTES = {
  SIGN_IN: '/sign_in',
  SIGN_IN_WITH_TOKEN: token => `/sign_in/${token}`,
  SIGN_UP: '/sign_up',
  RESET_PASSWORD: token => (token ? `/password/reset/${token}` : '/password/reset'),
  CALENDAR_BOOKINGS: spaceId => `/bookings/space/${spaceId}`,
  SPACES: '/spaces/user',
  PROFILE: '/profile',
  TEAM: '/team',
  ADMINS: '/admins',
  SUPPORT: '/support',
  MEMBER_BY_ID: id => `/members/${id}`,
  UNASSIGNED_CARDS: '/cards/unassigned',
  MY_INVOICES: '/invoices/my',
  SEND_FEEDBACK: '/support/feedback',
  CHAT_CHANNEL: chatId => (chatId ? `/support/chats/${chatId}` : '/support/chat'),
  CHAT_MESSAGE: chatId => `/support/chats/${chatId}/message`,
  CHATS: '/support/chats',
  USERS_SUPPORT_CONTACT: '/spaces/user/support',
  ADDONS: '/addons',
  ADDON_CATEGORIES: '/addon_categories',
  REQUEST_ADDON: addonId => `/addons/${addonId}/add`,
  CANCEL_ADDON: addonId => `/addons/${addonId}/cancel`,
  MY_ADDONS: '/addons/my',
  MY_DOCUMENTS: '/documents/my',
  POLICY_BY_TYPE: type => `/policies/types/${type}`,
  RESEND_INVITE: '/sign_up/resend_invite',
  STRIPE_SESSION_TOKEN: '/stripe/session',
  STRIPE_PAYMENT_INFO: '/stripe/payment_info',
};

import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Text } from 'rebass';
import { observable } from 'mobx';
import BaseLayout from '../_common/layouts/BaseLayout';
import { colors, FONT_FAMILY } from '../theme';
import MembersInvitationForm from './MembersInvitationForm';
import MembersList from './MembersList';
import InvitationSuccessModal from './InvitationSuccessModal';

@inject('userStore')
@observer
class TeamManagement extends Component {
  @observable isOpen = false;

  render() {
    const {
      userStore: {
        profile,
        inviteMembers,
        invitedMembers,
        existingMembers,
      },
    } = this.props;
    return (
      <Box>
        <BaseLayout pt={100} pb={75}>
          <Text
            fontSize={40}
            color="black"
            fontFamily={FONT_FAMILY.HAILSR_BOLD}
            mb={50}
          >
            { `${profile.space ? (profile.space.name) : ''} Team Management` }
          </Text>
          <MembersList />
        </BaseLayout>
        <BaseLayout py={75} bgColor={colors.black}>
          <Text
            fontSize={28}
            color="seaGreen"
            fontFamily={FONT_FAMILY.HAILSR_BOLD}
            mb={25}
          >
            Are we missing anyone? Add them here:
          </Text>
          <MembersInvitationForm onSubmit={values => inviteMembers(values, () => { this.isOpen = true; })} />
          {this.isOpen && <InvitationSuccessModal invitedMembers={invitedMembers} existingMembers={existingMembers} onClose={() => { this.isOpen = false; }} />}
        </BaseLayout>
      </Box>
    );
  }
}

TeamManagement.wrappedComponent.propTypes = {
  userStore: PropTypes.shape({
    inviteMembers: PropTypes.func.isRequired,
  }),
};

export default TeamManagement;

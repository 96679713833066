import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Flex, Image, Text, Box,
} from 'rebass';
import Modal from '../_common/components/Modal';
import Button from '../_common/components/Button';
import { BUTTON_VARIANT, FONT_FAMILY } from '../theme';
import { APP_ROUTES } from '../_app/routes';

const modalContentStyles = { maxWidth: 588 };


const SuccessModal = ({ onCancel, isOpen }) => (
  <Modal isOpen={isOpen} onClose={onCancel} customStyles={{ content: modalContentStyles }} onRequestClose={onCancel}>
    <Flex flexDirection="column" bg="seaGreen">
      <Image
        src="/images/icons/x-icon.svg"
        p={12}
        alignSelf="flex-end"
        style={{ cursor: 'pointer' }}
        onClick={onCancel}
      />
      <Box px={38} pb={39} width={1}>
        <Text fontSize={27} mb={20} color="#fff" textAlign="center" pt="5px">
          Success!
        </Text>
        <Text color="#fff" fontSize={21}>
          Your request has been sent. Your Workspace Experience Coordinator will be in touch shortly with more details
        </Text>
        <Flex mt={30} justifyContent="center" width={1}>
          <Button
            style={{
              boxShadow: 'none',
              minWidth: 246,
              fontWeight: 400,
              fontFamily: FONT_FAMILY.HAILSR_MEDIUM,
            }}
            mr={10}
            onClick={onCancel}
          >
            Add another
          </Button>
          <Link to={APP_ROUTES.PAYMENTS}>
            <Button
              ml={10}
              variant={BUTTON_VARIANT.REVERSE}
              style={{
                color: '#fff',
                backgroundColor: 'transparent',
                border: '1px solid #fff',
                minWidth: 246,
                boxShadow: 'none',
                fontWeight: 400,
                fontFamily: FONT_FAMILY.HAILSR_MEDIUM,
              }}
            >
              See in Plans & Payments
            </Button>
          </Link>
        </Flex>
      </Box>
    </Flex>
  </Modal>
);

SuccessModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SuccessModal;

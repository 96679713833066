export const TOKEN_KEY = 'yoursWorkLife';

export const CARD_ASSIGNMENT_STATUS = {
  ASSIGNED: 'Card assigned',
  NOT_ASSIGNED: 'No card assigned ',
};

export const ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  SECONDARY_ADMIN: 'SECONDARY_ADMIN',
  MEMBER: 'MEMBER',
};

export const PRIORITY_LEVELS = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const FEEDBACK_TOPICS = [
  { label: 'IT', value: 'IT' },
  { label: 'Cleaning', value: 'Cleaning' },
  { label: 'Facilities', value: 'Facilities' },
  { label: 'Other', value: 'Other' },
];

export const SUPPORT_CHAT_TOPICS = [
  { label: 'Facilities', value: 'Facilities' },
  { label: 'Cleaning', value: 'Cleaning' },
  { label: 'Security', value: 'Security' },
  { label: 'IT', value: 'IT' },
  { label: 'Office Management', value: 'Office Management' },
  { label: 'Portal', value: 'Portal' },
];

import { action, computed, observable } from 'mobx';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import API from '../_app/api';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';
import routerStore from './routerStore';

export class SupportStore {
  @observable isLoading = false;
  @observable error = null;
  @observable faqs = {};
  @observable filter = '';
  @observable policy = {
    isLoading: false,
    data: {},
  };

  @computed get totalFaqs() {
    return Object.keys(this.faqs).length;
  }

  @action setFilter(filter) {
    this.filter = filter;
  }

  @action fetchSupport = async (search = '') => {
    try {
      this.isLoading = true;
      const {
        data: { data },
      } = await API(`${API_ROUTES.SUPPORT}?search=${search}`);
      this.faqs = data;
    } catch (e) {
      const error = e.error || e.message;
      this.error = error;
      toast.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action sendFeedback = async ({ topic, message }, successCb) => {
    try {
      await API.post(API_ROUTES.SEND_FEEDBACK, { topic, message });
      successCb();
    } catch (e) {
      toast.error(e.error || e.message || 'Something went wrong. Please, try again');
    }
  };

  @action fetchPolicy = async ({ type }) => {
    this.policy.isLoading = true;
    try {
      const { data: { data } } = await API(API_ROUTES.POLICY_BY_TYPE(type));
      if (isEmpty(data)) {
        return routerStore.push(APP_ROUTES.ROOT);
      }
      this.policy.data = data;
    } catch (e) {
      routerStore.push(APP_ROUTES.ROOT);
    } finally {
      this.policy.isLoading = false;
    }
  };
}

export default new SupportStore();

import React from 'react';
import PropTypes from 'prop-types';
import {
  Text, Card, Flex, Box,
} from 'rebass';
import styled from 'styled-components';
import get from 'lodash/get';
import Button from '../_common/components/Button';
import {
  FONT_FAMILY, CARD_VARIANT, BUTTON_VARIANT,
} from '../theme';
import RequestAddonModal from './RequestAddonModal';

const TileImage = styled(Flex)`
  height: 208px;
  width: 100%;
  background-image: url(${({ photoUrl }) => photoUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;


const AddonTile = ({
  name, description, additionalInfo, _id: addonId, photos, isRecurring,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Card
      variant={CARD_VARIANT.TERTIARY}
      mb={20}
      style={{
        boxShadow: '0 10px 17px 0 rgba(12, 16, 22, 0.15)',
        border: 'none',
        borderRadius: '15px',
      }}
    >
      <TileImage justifyContent="center" photoUrl={get(photos, '[0]', '/images/dashboard/payments-and-billing.png')} />
      <Flex flexDirection="column" p={22}>
        <Box style={{ minHeight: 135 }}>
          <Text fontSize={21} fontFamily={FONT_FAMILY.HAILSR_BOLD} mb="8px" color="seaGreen">{name}</Text>
          <Text fontSize={14} mb={25} fontFamily={FONT_FAMILY.HAILSR_BOOK}>
            {description}
          </Text>
        </Box>
        <Button
          variant={BUTTON_VARIANT.SECONDARY}
          width={[1, 1, 1, 0.5]}
          style={{
            fontWeight: 400, cursor: 'pointer', boxShadow: 'none', fontFamily: FONT_FAMILY.HAILSR_MEDIUM,
          }}
          onClick={() => setIsOpen(true)}
        >
          Request
        </Button>
      </Flex>
      <RequestAddonModal
        addonId={addonId}
        isRecurring={isRecurring}
        addonName={name}
        additionalInfo={additionalInfo}
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        onConfirm={() => setIsOpen(false)}
      />
    </Card>
  );
};

AddonTile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string,
  photos: PropTypes.array,
  _id: PropTypes.string,
  isRecurring: PropTypes.bool,
};

export default AddonTile;

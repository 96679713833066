import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import { APP_ROUTES } from '../_app/routes';
import Button from '../_common/components/Button';
import { Error } from '../_common/components/forms/FormError';
import TextInput from '../_common/components/forms/TextInput';
import { FONT_FAMILY } from '../theme';
import { composeValidators, mustBeValidEmail, required } from '../utils/formValidators';

const SignInForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" mb={40}>
          <Box mb="20" width={[1, 0.5, 0.5]}>
            <Field
              label="Email"
              name="email"
              type="email"
              component={TextInput}
              validate={composeValidators(required, mustBeValidEmail)}
              width={1}
            />
          </Box>
          <Field
            label="Password"
            name="password"
            type="password"
            component={TextInput}
            validate={composeValidators(required)}
            width={[1, 0.5, 0.5]}
          />
          <Flex width={[1, 0.5, 0.5]} justifyContent="flex-end" mt={10}>
            <Link style={{ color: 'initial' }} to={APP_ROUTES.FORGOT_PASSWORD}>
              <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Forgot password?
              </Text>
            </Link>
          </Flex>
        </Flex>
        {submitError && (
          <Flex mt={-20} mb={20} justifyContent="center">
            <Error>{submitError}</Error>
          </Flex>
        )}
        <Flex justifyContent="center">
          <Button
            type="submit"
            loading={submitting}
            disabled={submitting}
            minWidth={211}
          >
            Sign In
          </Button>
        </Flex>
      </form>
    )}
  />
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignInForm;

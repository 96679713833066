import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { OnChange } from 'react-final-form-listeners';
import qs from 'query-string';
import { Flex, Box } from 'rebass';
import TextInput, { TEXT_INPUT_VARIANT } from '../_common/components/forms/TextInput';
import CheckboxButton from '../_common/components/forms/CheckboxButton';
import { APP_ROUTES } from '../_app/routes';
import Loader from '../_common/components/Loader';

let timeout = null;
const AddonFiltersForm = ({
  history, location, categories, isLoading, initialValues,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={(values) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const params = qs.parse(location.search);
        if (!values.phrase) delete params.phrase;
        if (!values.categoryId) delete params.categoryId;
        history.push(
          `${APP_ROUTES.EXTRAS}?${qs.stringify({
            ...params,
            ...values,
          })}`,
        );
      }, 500);
    }}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <OnChange name="phrase">
          {() => {
            handleSubmit();
          }}
        </OnChange>
        <Flex flexDirection="column" mb={34}>
          <Flex justifyContent="flex-end" mx={-16}>
            <Box px={16} width={342} mb={25}>
              <Field
                name="phrase"
                placeholder="Keyword search"
                variant={TEXT_INPUT_VARIANT.SECONDARY}
                component={TextInput}
                icon="/images/icons/search-icon.png"
              />
            </Box>
          </Flex>
          <Flex m={-16} flexWrap="wrap">
            {categories.map((cat, idx) => (
              <Box p={10} key={`addon-category-wrapper-${idx}`}>
                <Field
                  type="checkbox"
                  name="categoryId"
                  component={CheckboxButton}
                  afterChange={() => handleSubmit()}
                  value={cat._id}
                  label={cat.name}
                />
              </Box>
            ))}
            {isLoading && <Loader fullPage={false} height={40} />}
          </Flex>
        </Flex>
      </form>
    )}
  />
);

AddonFiltersForm.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  categories: PropTypes.array,
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default withRouter(AddonFiltersForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILY } from '../../../theme';

const CheckboxInput = styled('input')`
  position: absolute;
  visibility: hidden;
  &:checked + label {
    box-shadow: 0 2px 7px 0 rgba(37, 179, 176, 0.46);
    border: solid 2px #25b3b0;
    background-image: linear-gradient(to bottom, #28c3c0 0%, #24aba9);
  }
`;

const Label = styled(Flex)`
  cursor: pointer;
  min-width: 120px;
  min-height: 57px;
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(24, 33, 47, 0.36);
  border: solid 2px #18212f;
  background-image: linear-gradient(to bottom, #2c3e5a 0%, #18212f);
  color: #fff;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  font-family: ${FONT_FAMILY.HAILSR_BOLD};
  padding: 5px;
  :hover {
    opacity: 0.85;
  }
`;

const CheckboxButton = ({
  label, input, afterChange, ...props
}) => (
  <Flex sx={{ position: 'relative' }}>
    <CheckboxInput
      {...input}
      id={input.value}
      type="checkbox"
      onChange={(e) => {
        input.onChange(e);
        if (typeof afterChange === 'function') {
          afterChange(input.value);
        }
      }}
    />
    <Label as="label" htmlFor={input.value} style={{ ...props.style }} {...props}>
      {label}
    </Label>
  </Flex>
);

CheckboxButton.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  afterChange: PropTypes.func,
};

export default CheckboxButton;

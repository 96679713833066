import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Flex } from 'rebass';
import TextInput, { TEXT_INPUT_VARIANT } from '../_common/components/forms/TextInput';

const SearchForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({
      handleSubmit,
    }) => (
      <form onSubmit={handleSubmit}>
        <Flex width={[1, 'fit-content', 'fit-content']}>
          <Field
            name="filter"
            placeholder="Search"
            variant={TEXT_INPUT_VARIANT.SECONDARY}
            width={[1, 342, 342]}
            component={TextInput}
            icon="/images/icons/search-icon.png"
          />
        </Flex>
      </form>
    )}
  />
);

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SearchForm;

import validator from 'validator';

export const composeValidators = (...validators) => (value, values) => validators.reduce(
  (error, _validator) => error || _validator(value, values),
  undefined,
);

export const required = value => (value ? undefined : 'This field is required');

export const minLength = length => (value = '') => (value.length >= length ? undefined : `Must be at least ${length} characters long.`);

export const mustBeValidEmail = value => (validator.isEmail(value) ? undefined : 'Invalid email adress');

export const passwordsMustMatch = (value, allValues) => (value === allValues.password ? undefined : 'Password confirmation must match');

export const passwordLength = length => (value = '') => (value === '' || value.length >= length ? undefined : `Password needs to be ${length} characters or longer`);

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 45px)',
    maxWidth: '720px',
    maxHeight: '95vh',
    border: 'none',
    borderRadius: '5px',
    padding: '0px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.5)',
    overflow: 'unset',
    overflowY: 'scroll',
  },
  overlay: {
    zIndex: 1501,
    backgroundColor: 'rgba(33, 40, 64, 0.85)',
  },
};

const BaseModal = ({
  children, title, onClose, ...props
}) => (
  <Modal
    {...props}
    style={{
      content: { ...customStyles.content, ...props.customStyles.content },
      overlay: customStyles.overlay,
    }}
  >
    {children}
  </Modal>
);

BaseModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
};
BaseModal.defaultProps = {
  customStyles: {
    content: {},
  },
};

export default BaseModal;

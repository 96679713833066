import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import HorizontalLine from '../_common/components/HorizontalLine';
import Modal from '../_common/components/Modal';
import { FONT_FAMILY } from '../theme';
import UserDetailsForm from './UserDetailsForm';

@inject('membersStore')
class UserDetailsModal extends Component {
  onSubmit = async (values) => {
    const { membersStore, onClose } = this.props;
    await membersStore.updateMember(values);
    await membersStore.fetchMembers();
    onClose();
  };

  render() {
    const { onClose, member, membersStore: { resendInvite } } = this.props;
    return (
      <Modal
        isOpen
        onClose={onClose}
        onRequestClose={onClose}
      >
        <Flex flexDirection="column" p={[10, 20, 40]}>
          <Text
            fontSize={28}
            color="seaGreen"
            fontFamily={FONT_FAMILY.HAILSR_BOLD}
            lineHeight={1}
          >
            User Details
          </Text>
          <HorizontalLine my={40} />
          <UserDetailsForm
            initialValues={member}
            onSubmit={this.onSubmit}
            onClose={onClose}
            resendInvite={resendInvite}
          />
        </Flex>
      </Modal>
    );
  }
}

UserDetailsModal.wrappedComponent.propTypes = {
  membersStore: PropTypes.shape({
    updateMember: PropTypes.func.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
};

export default withRouter(UserDetailsModal);

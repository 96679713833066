import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { APP_ROUTES } from '../_app/routes';
import routerStore from './routerStore';
import userStore from './userStore';

export class PaymentStore {
  @observable isLoading = false;
  @observable data = null;
  @observable error = null;
  @observable goCardlessError = null;
  @observable payments = [];

  @action
  startGoCardlessFlow = async (redirectUrl) => {
    try {
      this.isLoading = true;
      const { data } = await API.post('/payments', {
        redirectUrl: redirectUrl || `${process.env.REACT_APP_URL}${APP_ROUTES.GO_CARDLESS}`,
      });
      this.data = data.data;
      this.goCardlessError = null;
    } catch (error) {
      const errorMessage = error.message || 'Error!';
      toast.error(errorMessage);
      this.goCardlessError = errorMessage;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  completeGoCardlessFlow = async (redirectFlowId, redirectToYourTeam = false) => {
    try {
      this.isLoading = true;
      const { data } = await API.post('/payments/complete', {
        redirectFlowId,
      });
      this.data = data.data;
      if (redirectToYourTeam) {
        routerStore.push(APP_ROUTES.YOUR_TEAM);
      } else {
        await userStore.fetchProfile();
      }
    } catch (error) {
      console.error(error);
      this.error = error.message;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchPayments = async () => {
    try {
      this.isLoading = true;
      const { data: { data } } = await API.get('/payments');
      this.payments = data;
    } catch (error) {
      console.error(error);
      this.error = error.message;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new PaymentStore();

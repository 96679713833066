import { action, extendObservable } from 'mobx';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

const initialState = {
  isLoading: false,
  spaces: [],
  bookings: [],
};

export class BookingsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  fetchSpaces = async () => {
    this.isLoading = true;
    try {
      const {
        data: { data },
      } = await API(API_ROUTES.SPACES);
      this.spaces = data;
    } catch (e) {
      toast.error(e.error || e.message || 'Error!');
    } finally {
      this.isLoading = false;
    }
  };

  @action
  fetchBookingsForCalendar = async (spaceId) => {
    this.isLoading = true;
    try {
      const {
        data: { data },
      } = await API(API_ROUTES.CALENDAR_BOOKINGS(spaceId));
      this.bookings = data.map(booking => ({
        ...booking,
        checkIn: new Date(booking.checkIn),
        checkOut: new Date(booking.checkOut),
      }));
    } catch (e) {
      toast.error(e.error || e.message || 'Error!');
    } finally {
      this.isLoading = false;
    }
  };
}

export default new BookingsStore();

import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import styled from 'styled-components';
import Loader from '../_common/components/Loader';
import DeleteUserModal from '../teamManagement/DeleteUserModal';
import UserDetailsModal from '../teamManagement/UserDetailsModal';

const Row = styled(Flex)`
  border-bottom: 1px solid rgba(111, 111, 111, 0.5);
`;

@inject('membersStore')
@observer
class AdminsList extends Component {
  state = {
    isUpdateModalOpened: false,
    isDeleteModalOpened: false,
    member: {},
  };

  componentDidMount() {
    this.props.membersStore.fetchAdmins();
  }

  onDelete = async () => {
    const { member } = this.state;
    const { deleteMember } = this.props.membersStore;
    await deleteMember(member._id);
    this.setState({ isDeleteModalOpened: false });
  };

  render() {
    const {
      membersStore: {
        isLoading,
        members,
      },
      isSuperAdmin,
    } = this.props;
    return (
      <Flex flexDirection="column" mb={25}>
        {isLoading && <Loader />}
        <Row />
        {members.map(member => (
          <Row py={15} key={member._id}>
            <Box width={1 / 2} pr={10}>
              <Text style={{ wordBreak: 'break-all' }} color="white">{member.email}</Text>
            </Box>
            <Box width={1 / 2} px={10}>
              <Text style={{ wordBreak: 'break-all' }} color="white" width={1}>
                {`${member.firstName} ${member.lastName}`}
              </Text>
            </Box>
            {isSuperAdmin && (
              <Flex width={150} pl={10} alignItems="center">
                <Image
                  mr="auto"
                  style={{ cursor: 'pointer' }}
                  src="/images/icons/edit-icon.png"
                  onClick={() => this.setState({
                    isUpdateModalOpened: true,
                    member,
                  })}
                />
                <Image
                  style={{ cursor: 'pointer' }}
                  src="/images/icons/x-icon.png"
                  onClick={() => this.setState({
                    isDeleteModalOpened: true,
                    member,
                  })}
                />
              </Flex>
            )}
          </Row>
        ))}
        {this.state.isUpdateModalOpened && (
          <UserDetailsModal
            onClose={() => this.setState({ isUpdateModalOpened: false })}
            member={this.state.member}
          />
        )}
        {this.state.isDeleteModalOpened && (
          <DeleteUserModal
            onConfirm={this.onDelete}
            onCancel={() => this.setState({ isDeleteModalOpened: false })}
            email={this.state.member.email}
          />
        )}
        {isEmpty(members) && !isLoading && (
          <Text color="white" py={15}>No results.</Text>
        )}
      </Flex>
    );
  }
}

AdminsList.wrappedComponent.propTypes = {
  membersStore: PropTypes.shape({
    fetchAdmins: PropTypes.func.isRequired,
    deleteMember: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    members: PropTypes.array.isRequired,
  }),
  isSuperAdmin: PropTypes.bool.isRequired,
};

export default AdminsList;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as RawButton, Image } from 'rebass';
import { BUTTON_VARIANT, FONT_FAMILY } from '../../theme';

const StyledButton = styled(RawButton)`
  text-shadow: none;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  line-height: 1;
  font-family: ${props => props.theme.fonts[FONT_FAMILY.HAILSR_BOLD]};
  cursor: pointer;
  min-width: ${props => props.minWidth || 160}px;
  transition: all 0.5s cubic-bezier(0.25, 0.25, 0, 1);
  box-shadow: 0 3px 5px 0 ${props => props.theme.colors.black};
`;

const Button = ({ children, loading, ...props }) => (
  <StyledButton {...props} style={{ position: 'relative', ...props.style }}>
    {loading ? <Image width={27} height="auto" src="/images/loader.gif" /> : children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    BUTTON_VARIANT.PRIMARY,
    BUTTON_VARIANT.SECONDARY,
    BUTTON_VARIANT.REVERSE,
  ]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  style: PropTypes.object,
};

Button.defaultProps = {
  px: 15,
  py: 15,
  loading: false,
  variant: BUTTON_VARIANT.PRIMARY,
};

export default Button;

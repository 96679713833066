import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import { APP_ROUTES } from '../_app/routes';
import Button from '../_common/components/Button';
import { FONT_FAMILY } from '../theme';
import ResetPasswordForm from './ResetPasswordForm';

@inject('authStore', 'routerStore')
@observer
class ResetPasswordPage extends Component {
  @observable isPasswordSet = false;

  onSubmit = async ({ password }) => {
    const { token } = this.props.match.params;
    return this.props.authStore.resetPassword({ token, password }, () => {
      this.isPasswordSet = true;
    });
  };

  render() {
    return (
      <Fragment>
        <Text
          mt={50}
          fontSize={70}
          textAlign="center"
          color="white"
          lineHeight={1}
          mb={40}
        >
          Reset password
        </Text>
        {this.isPasswordSet ? (
          <Flex alignItems="center" flexDirection="column">
            <Text
              fontSize={35}
              fontFamily={FONT_FAMILY.HAILSR_BOOK}
              textAlign="center"
              color="black"
              lineHeight={1}
              mb={40}
            >
              Password successfully set
            </Text>
            <Link to={APP_ROUTES.SIGN_IN}>
              <Button>Sign In</Button>
            </Link>
          </Flex>
        ) : (
          <ResetPasswordForm onSubmit={this.onSubmit} />
        )}
      </Fragment>
    );
  }
}

ResetPasswordPage.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    resetPassword: PropTypes.func,
  }).isRequired,
  routerStore: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.object,
};

export default ResetPasswordPage;

import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import Button from '../_common/components/Button';
import TextInput, { TEXT_INPUT_VARIANT } from '../_common/components/forms/TextInput';
import { BUTTON_VARIANT, FONT_FAMILY } from '../theme';
import { composeValidators, mustBeValidEmail, required } from '../utils/formValidators';

const MembersInvitationForm = ({ onSubmit }) => (
  <Form
    onSubmit={(values, form) => onSubmit(values, () => form.reset())}
    mutators={{ ...arrayMutators }}
    initialValues={{
      members: [
        { email: undefined, fullName: undefined },
      ],
    }}
    render={({
      handleSubmit,
      form: { mutators: { push } },
      submitting,
      pristine,
    }) => (
      <form onSubmit={handleSubmit}>
        <FieldArray name="members">
          {({ fields }) => fields.map((name, index) => (
            <Flex key={name} mb={10}>
              <Box width={1} mr={10}>
                <Field
                  name={`${name}.email`}
                  component={TextInput}
                  placeholder="email@example.com"
                  validate={composeValidators(required, mustBeValidEmail)}
                  variant={TEXT_INPUT_VARIANT.SECONDARY}
                />
              </Box>
              <Box width={1} ml={10}>
                <Field
                  name={`${name}.fullName`}
                  component={TextInput}
                  placeholder="Name (optional)"
                  variant={TEXT_INPUT_VARIANT.SECONDARY}
                />
              </Box>
              <Flex style={{ flexShrink: 0 }} ml={70} width={20} alignItems="center" justifyContent="center">
                <Image
                  style={{ cursor: 'pointer' }}
                  src="/images/icons/x-icon.png"
                  onClick={() => {
                    if (fields.length > 1) {
                      fields.remove(index);
                    }
                  }}
                />
              </Flex>
            </Flex>
          ))}
        </FieldArray>
        <Flex mt={30} alignItems="center">
          <Text
            fontFamily={FONT_FAMILY.HAILSR_BOOK}
            fontSize={19}
            color="seaGreen"
            onClick={() => push('members', undefined)}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            + Add another
          </Text>
          <Button
            ml="auto"
            type="submit"
            disabled={pristine || submitting}
            variant={BUTTON_VARIANT.SECONDARY}
          >
            Send Invitations
          </Button>
        </Flex>
      </form>
    )}
  />
);

MembersInvitationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MembersInvitationForm;

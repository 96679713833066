import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from 'prop-types';
import API from '../../../_app/api';
import { TextInputWrapper, TEXT_INPUT_VARIANT } from './TextInput';
import { MenuList, IndicatorSeparator, customStyles } from './FormSelect';
import Label from './FormLabel';

const AsyncAutocomplete = ({
  input,
  label,
  variant,
  url,
  dataMapper,
  defaultFieldValue,
  ...props
}) => {
  let timeout = null;
  const [selectedValue, setSelectedValue] = React.useState(defaultFieldValue);

  const loadOptions = async value => new Promise((resolve) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const {
          data: { data = [] },
        } = await API(`${url}?phrase=${value}`);
        resolve(dataMapper(data));
      } catch (e) {
        resolve([]);
      }
    }, 700);
  });

  return (
    <TextInputWrapper flexDirection="column" variant={variant}>
      {label && <Label>{label}</Label>}
      <AsyncSelect
        components={{ IndicatorSeparator, MenuList }}
        defaultOptions
        styles={customStyles}
        cacheOptions
        loadOptions={loadOptions}
        {...input}
        {...props}
        value={selectedValue || defaultFieldValue}
        onChange={(option) => {
          input.onChange(option.value);
          setSelectedValue(option);
        }}
      />
    </TextInputWrapper>
  );
};

AsyncAutocomplete.propTypes = {
  url: PropTypes.string.isRequired,
  input: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
  dataMapper: PropTypes.func,
  defaultFieldValue: PropTypes.any,
};
AsyncAutocomplete.defaultProps = {
  variant: TEXT_INPUT_VARIANT.GHOST_SECONDARY,
  dataMapper: arr => arr,
  defaultFieldValue: null,
};

export default AsyncAutocomplete;

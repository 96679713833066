import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Flex } from 'rebass';
import { OnChange } from 'react-final-form-listeners';
import moment from 'moment';
import Button from '../_common/components/Button';
import { Error } from '../_common/components/forms/FormError';
import TextInput from '../_common/components/forms/TextInput';
import { BUTTON_VARIANT, FONT_FAMILY } from '../theme';
import Checkbox from '../_common/components/forms/Checkbox';
import Select from '../_common/components/forms/FormSelect';
import HorizontalLine from '../_common/components/HorizontalLine';
import DatePicker from '../_common/components/forms/DatePicker';
import { recurrenceOptions } from '../utils/recurrenceHelpers';
import { required } from '../utils/formValidators';

const filterDate = (date) => {
  const now = moment().startOf('day');
  const day = moment(date).day();
  return day !== 0 && day !== 6 && now.isSameOrBefore(moment(date));
};

const startDate = () => {
  let date = moment();
  while (date.day() === 0 || date.day() === 6) {
    date = moment(date).add({ day: 1 });
  }
  return date.toDate();
};

const AddonConfirmationForm = ({
  onSubmit,
  additionalInfo,
  isRecurring,
  onCancel,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{ startsAt: startDate() }}
    render={({
      handleSubmit, submitError, submitting, values, form: { change },
    }) => (
      <form onSubmit={handleSubmit}>
        <OnChange name="startsAt">
          {() => {
            const { recurrenceRule } = values;
            change('recurrenceRule', '123456');
            setTimeout(() => {
              // hack for input select label to dynamic update
              change('recurrenceRule', recurrenceRule);
            }, 0);
          }}
        </OnChange>
        <Flex width={1} justifyContent="center" alignItems="center" flexDirection="column">
          <Box mb={[10, 20]} width={1}>
            {additionalInfo ? (
              <Field
                label={additionalInfo}
                name="additionalInfo"
                type="textarea"
                component={TextInput}
                placeholder="Write your description here..."
                width={1}
                rows={3}
                style={{
                  borderRadius: '5px',
                  boxShadow: 'none',
                  border: 'solid 1px rgba(24, 33, 47, 0.15)',
                  padding: 20,
                }}
              />
            ) : (
              <HorizontalLine mt={10} />
            )}
          </Box>
        </Flex>
        <Box mb={[20, 30]} width={1}>
          <Field label="Select start date" name="startsAt" component={DatePicker} filterDate={filterDate} />
        </Box>
        {isRecurring && (
          <>
            <Box my={20} width={1}>
              <Field
                label="Is it recurring?"
                name="isRecurring"
                type="checkbox"
                id="recurring-checkbox"
                component={Checkbox}
              />
            </Box>
            {values.isRecurring && (
              <>
                <Box mb={[30, 60]} width={1}>
                  <Field
                    label="Choose recurrence rule"
                    name="recurrenceRule"
                    component={Select}
                    validate={required}
                    width={1}
                    options={recurrenceOptions(values.startsAt)}
                  />
                </Box>
              </>
            )}
          </>
        )}
        {submitError && (
          <Flex mt={-20} mb={20} justifyContent="center">
            <Error>{submitError}</Error>
          </Flex>
        )}
        <Flex mt={[40, 80]} flexWrap={['wrap', 'nowrap']}>
          <Button
            loading={submitting}
            disabled={submitting}
            width={[1, 1, 0.3]}
            variant={BUTTON_VARIANT.SECONDARY}
            type="submit"
            style={{
              fontWeight: 400, cursor: 'pointer', boxShadow: 'none', fontFamily: FONT_FAMILY.HAILSR_MEDIUM,
            }}
            mb={[20, 0]}
          >
            Send request
          </Button>
          <Box width={24} />
          <Button
            type="button"
            width={[1, 1, 0.3]}
            variant={BUTTON_VARIANT.REVERSE}
            onClick={onCancel}
            style={{
              fontWeight: 400,
              cursor: 'pointer',
              boxShadow: 'none',
              fontFamily: FONT_FAMILY.HAILSR_MEDIUM,
              border: '1px solid rgba(0, 0, 0, 0.5)',
              color: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            Cancel
          </Button>
        </Flex>
      </form>
    )}
  />
);

AddonConfirmationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  additionalInfo: PropTypes.string,
  onCancel: PropTypes.func,
  isRecurring: PropTypes.bool,
};

export default AddonConfirmationForm;

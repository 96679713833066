import React from 'react';
import { FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';
import diff from 'object-diff';

class AutoSubmit extends React.Component {
  state = {
    values: this.props.values,
  };

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { values, submit } = this.props;

    const difference = diff(this.state.values, values);
    if (Object.keys(difference).length) {
      this.setState({ values });
      this.promise = submit(difference);
      await this.promise;
      delete this.promise;
    }
  };

  render() {
    return null;
  }
}

AutoSubmit.propTypes = {
  values: PropTypes.object,
  debounce: PropTypes.number,
  submit: PropTypes.func,
};

export default props => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSubmit} />
);

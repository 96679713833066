import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Text } from 'rebass';
import { ROLE } from '../_app/constants';
import BaseLayout from '../_common/layouts/BaseLayout';
import { colors, FONT_FAMILY } from '../theme';
import AdminsList from './AdminsList';
import ProfileForm from './ProfileForm';

@inject('userStore')
@observer
class AccountPage extends Component {
  componentDidMount() {
    this.props.userStore.fetchProfile();
  }

  isSuperAdmin = () => {
    const { profile } = this.props.userStore;
    const role = get(profile, 'role');
    return role === ROLE.SUPER_ADMIN;
  };

  render() {
    const { profile, isLoading, updateProfile } = this.props.userStore;
    return (
      <Box>
        <BaseLayout pt={100} pb={50}>
          <Text
            fontSize={40}
            color="seaGreen"
            fontFamily={FONT_FAMILY.HAILSR_BOLD}
          >
            {this.isSuperAdmin() ? (
              `${profile.space ? (profile.space.name) : ''} Account Administrator`
            ) : (
              'Your details'
            )}
          </Text>
        </BaseLayout>
        <BaseLayout pt={50} pb={50} bgColor={colors.black}>
          <Box width={[1, 1, 0.7]}>
            <ProfileForm
              initialValues={profile}
              onSubmit={updateProfile}
              isLoading={isLoading}
            />
            <Text
              fontSize={27}
              color="seaGreen"
              mt={30}
              mb={15}
            >
              Secondary Administrators
            </Text>
            <AdminsList isSuperAdmin={this.isSuperAdmin()} />
          </Box>
        </BaseLayout>
      </Box>
    );
  }
}

AccountPage.wrappedComponent.propTypes = {
  userStore: PropTypes.shape({
    fetchProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AccountPage;

import moment from 'moment';

export const numberToDayString = (weekDayInMonth) => {
  switch (weekDayInMonth) {
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'last';
    default:
      return 'first';
  }
};

export const recurrenceOptions = (selectedDate) => {
  const date = moment(selectedDate || undefined);
  let weekDayInMonthNumber = 1;
  while (weekDayInMonthNumber * 7 < date.date()) {
    weekDayInMonthNumber++;
  }
  return [
    { label: 'Every weekday (Monday to Friday)', value: 'daily' },
    { label: `Weekly on ${date.format('dddd')}`, value: 'weekly' },
    { label: `Every 2 weeks on ${date.format('dddd')}`, value: 'every2weeks' },
    { label: `Monthly on ${numberToDayString(weekDayInMonthNumber)} ${date.format('dddd')}`, value: 'monthly' },
  ];
};

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '../../../_app/routes';
import { colors } from '../../../theme';
import Button from '../Button';
import Hamburger from './Hamburger';

const MenuItem = ({
  iconName, label, to,
}) => (
  <NavLink
    to={to}
    exact
    style={{ textDecoration: 'none', color: '#fff' }}
    activeStyle={{ color: colors.black, fill: '#fabe79' }}
  >
    <Flex alignItems="center" mx={iconName ? 10 : 0} style={{ height: '100%' }}>
      {iconName && <Image src={`/images/icons/${iconName}.png`} width={30} height="auto" mr={12} />}
      <Text
        style={{
          transition: 'all .3s',
        }}
        lineHeight={1}
        fontSize={20}
      >
        {label}
      </Text>
    </Flex>
  </NavLink>
);

MenuItem.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const MobileMenu = styled(Flex)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1100;
  width: 349px;
  transition: all 0.5s ease 0s;
  background-color: ${props => props.theme.colors.seaGreen};
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  border-bottom-left-radius: 40px;
  box-shadow: -5px 0 10px 0 rgba(24, 33, 47, 0.54);
  overflow-y: scroll;
  
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    width: 275px;
    border-bottom-left-radius: 0;
  }
`;

export const HideMobile = styled(Box)`
  @media screen and (max-width: 64em) {
    display: none;
  }
`;

export const HideDesktop = styled(Box)`
  @media screen and (min-width: 64em) {
    display: none;
  }
`;

const duration = 300;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  right: 0,
};

const transitionStyles = {
  entering: { right: 0 },
  entered: { right: 0 },
  exiting: { right: -349 },
  exited: { right: -349 },
};

const VerticaLine = styled(Box)`
  width: 100%;
  border-bottom: 1px solid #fff;
`;

const Cover = styled(Box)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(33, 40, 64, 0.85);
  z-index: 100;
`;
const StyledRouterLink = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const Menu = ({ logout, hamburgerColor }) => {
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    document.body.style.overflow = isOpened ? 'hidden' : 'initial';
    return () => {
      document.body.style.overflow = 'initial';
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  return (
    <Box ref={ref} style={{ position: 'relative', height: 26, width: 32 }}>
      <Box style={{ position: 'absolute', zIndex: 10000 }}>
        <Hamburger backgroundColor={hamburgerColor} isOpened={isOpened} onClick={() => setIsOpened(!isOpened)} />
      </Box>
      {isOpened && <Cover onClick={() => setIsOpened(false)} />}
      <Transition in={isOpened} timeout={duration}>
        {state => (
          <MobileMenu style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <Flex alignItems="center" pt={90} mb={30} onClick={() => setIsOpened(false)}>
              <MenuItem color="primaryText" label="Home" to={APP_ROUTES.ROOT} />
            </Flex>
            <VerticaLine />
            <Flex alignItems="center" my={30} onClick={() => setIsOpened(false)}>
              <MenuItem color="primaryText" label="Team Management" to={APP_ROUTES.TEAM_MANAGEMENT} />
            </Flex>
            <VerticaLine />
            <Flex alignItems="center" my={30} onClick={() => setIsOpened(false)}>
              <MenuItem color="primaryText" label="Payments & Billing" to={APP_ROUTES.PAYMENTS} />
            </Flex>
            <VerticaLine />
            <Flex alignItems="center" my={30} onClick={() => setIsOpened(false)}>
              <MenuItem color="primaryText" label="Account Details" to={APP_ROUTES.ACCOUNT} />
            </Flex>
            <VerticaLine />
            <Flex alignItems="center" my={30} onClick={() => setIsOpened(false)}>
              <MenuItem color="primaryText" label="Help Centre" to={APP_ROUTES.SUPPORT} />
            </Flex>
            <VerticaLine />
            <Flex alignItems="center" my={30} onClick={() => setIsOpened(false)}>
              <MenuItem color="primaryText" label="Extras" to={APP_ROUTES.EXTRAS} />
            </Flex>
            <Button mt={[20, 40, 90]} onClick={logout} mb="auto">
              Sign Out
            </Button>
            <Flex width={1} mt={25} justifyContent="flex-end">
              <StyledRouterLink onClick={() => setIsOpened(false)} to={APP_ROUTES.TERMS_AND_CODITIONS}>
                Terms & Conditions
              </StyledRouterLink>
            </Flex>
          </MobileMenu>
        )}
      </Transition>
    </Box>
  );
};

Menu.propTypes = {
  logout: PropTypes.func.isRequired,
  hamburgerColor: PropTypes.any,
};

export default Menu;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { injectStripe } from 'react-stripe-elements';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import withStripeWrapper from '../../hoc/withStripeWrapper';
import Loader from '../../_common/components/Loader';
import { APP_ROUTES } from '../../_app/routes';

@withRouter
@withStripeWrapper
@injectStripe
@inject('stripeStore')
@observer
class StripeCheckout extends Component {
  componentDidMount() {
    this.postSessionToken();
  }

  processCheckout = async () => {
    if (!this.props.stripe) {
      return toast.info('Service unavailable.');
    }
    const { redirectToCheckout } = this.props.stripe;
    const { fetchSessionToken } = this.props.stripeStore;
    return fetchSessionToken(redirectToCheckout);
  };

  postSessionToken = () => {
    const { session_id: sessionId, cancelled } = qs.parse(this.props.location.search) || {};
    if (sessionId) {
      const { postSessionToken } = this.props.stripeStore;
      postSessionToken(sessionId);
    }
    if (cancelled) {
      toast.info('Your action has been cancelled.');
      this.props.history.push(APP_ROUTES.PAYMENTS);
    }
  };

  render() {
    const { isLoading } = this.props.stripeStore;
    return (
      <>
        {isLoading && <Loader />}
        {this.props.children({ isLoading, onClick: this.processCheckout })}
      </>
    );
  }
}

StripeCheckout.propTypes = {
  stripeStore: PropTypes.shape({
    fetchSessionToken: PropTypes.func,
    postSessionToken: PropTypes.func,
    isLoading: PropTypes.bool,
  }),
  stripe: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.any,
};

export default StripeCheckout;

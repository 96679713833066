import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  Card, Flex, Text, Link,
} from 'rebass';
import moment from 'moment';
import HorizontalLine from '../../_common/components/HorizontalLine';
import { CARD_VARIANT, FONT_FAMILY } from '../../theme';
import Loader from '../../_common/components/Loader';

const InvoicesList = ({
  invoices, onLoadMore, hasMore, error, isLoading,
}) => (
  <Card
    variant={CARD_VARIANT.SECONDARY}
    mx={[0, 10, 10]}
    p={25}
    width={[1, 1 / 2, 1 / 2]}
    mb={[25, 0, 0]}
  >
    <Text
      fontFamily={FONT_FAMILY.HAILSR_BOLD}
      color="white"
      mb={25}
      fontSize={24}
    >
      Past Invoices
    </Text>
    <HorizontalLine mb={25} />
    {invoices.map((invoice, idx) => (
      <Flex justifyContent="space-between" py={15} key={`invoice-item-${idx}`}>
        <Text
          fontFamily={FONT_FAMILY.HAILSR_BOOK}
          fontSize={17}
          color="seaGreen"
        >
          {`${moment(invoice.DateString).format(
            'DD MMMM YYYY',
          )} | ${invoice.Total.toFixed(2)}${invoice.CurrencyCode}`}
        </Text>
        <Link
          target="_blank"
          href={invoice.invoiceUrl}
          color="white"
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          fontFamily={FONT_FAMILY.HAILSR_BOOK}
          fontSize={17}
        >
          View Invoice
        </Link>
      </Flex>
    ))}
    {isLoading && <Loader fullPage={false} />}
    {isEmpty(invoices) && !isLoading && (
      <Text color="white" mb={45} fontSize={20}>
        No invoices found
      </Text>
    )}
    <HorizontalLine my={25} />
    {!error && !isEmpty(invoices) && hasMore && (
      <Text
        fontSize={17}
        color="white"
        textAlign="center"
        onClick={() => onLoadMore()}
        style={{ cursor: 'pointer' }}
      >
        Older
      </Text>
    )}
  </Card>
);

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
};

export default InvoicesList;

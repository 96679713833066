import { inject, observer } from 'mobx-react/index';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Text } from 'rebass';
import ForgotPasswordForm from './ForgotPasswordForm';

@inject('authStore')
@withRouter
@observer
class ForgotPassword extends Component {
  render() {
    const { resetPasswordRequest } = this.props.authStore;
    return (
      <Fragment>
        <Text
          mt={50}
          fontSize={70}
          textAlign="center"
          color="white"
          lineHeight={1}
          mb={40}
        >
          Forgot password
        </Text>
        <ForgotPasswordForm onSubmit={resetPasswordRequest} />
      </Fragment>
    );
  }
}

ForgotPassword.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    resetPasswordRequest: PropTypes.func,
  }).isRequired,
};

export default ForgotPassword;

import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Flex } from 'rebass';
import Button from '../_common/components/Button';
import { AbsolutePositionedErrorsForm } from '../_common/components/forms/FormError';
import FormSelect from '../_common/components/forms/FormSelect';
import TextInput from '../_common/components/forms/TextInput';
import { BUTTON_VARIANT } from '../theme';
import { required } from '../utils/formValidators';
import { FEEDBACK_TOPICS } from '../_app/constants';

const FeedbackForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({
      handleSubmit,
      submitting,
    }) => (
      <AbsolutePositionedErrorsForm onSubmit={handleSubmit}>
        <Flex flexDirection="column">
          <Box mb={25}>
            <Field
              name="topic"
              component={FormSelect}
              options={FEEDBACK_TOPICS}
              placeholder="Topic"
              width={1}
              validate={required}
            />
          </Box>
          <Field
            name="message"
            component={TextInput}
            type="textarea"
            rows={5}
            validate={required}
          />
          <Flex justifyContent="flex-end">
            <Button
              mt={25}
              type="submit"
              disabled={submitting}
              variant={BUTTON_VARIANT.SECONDARY}
              loading={submitting}
            >
              Send
            </Button>
          </Flex>
        </Flex>
      </AbsolutePositionedErrorsForm>
    )}
  />
);

FeedbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FeedbackForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex } from 'rebass';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import qs from 'query-string';
import isString from 'lodash/isString';
import BaseLayout from '../_common/layouts/BaseLayout';
import { FONT_FAMILY } from '../theme';
import AddonTile from './AddonTile';
import AddonFiltersForm from './AddonFiltersForm';
import Loader from '../_common/components/Loader';
import Button from '../_common/components/Button';

const TextWrapper = styled(Flex)`
  max-width: 1120px;
  margin: 0 auto;
`;

const TextItem = styled(Text)`
  color: #fff;
  font-family: ${FONT_FAMILY.HAILSR_BOLD};
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
`;

const Wrapper = styled(Box)`
  min-height: 100vh;
  background-image: url('/images/you-symbol-grey-top.svg'), url('/images/you-symbol-grey-bottom.svg');
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-position-y: 21vh,calc(100% + 300px);
  background-position-x: calc(0% - 165px), calc(100% + 550px);
`;

@inject('addonsStore')
@observer
class Addons extends Component {
  @observable isOpen = false;

  componentDidMount() {
    this.props.addonsStore.fetchAddonCategories();
    this.props.addonsStore.fetchAllAddons();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.props.addonsStore.fetchAllAddons();
    }
  }

  initialValues = () => {
    const { categoryId, ...rest } = qs.parse(this.props.location.search);
    if (categoryId) {
      return {
        ...rest,
        categoryId: isString(categoryId) ? [categoryId] : categoryId,
      };
    }
    return {
      ...rest,
    };
  };

  toggleModal = () => {
    this.isOpen = !this.isOpen;
  };

  render() {
    const { data: categories, isLoading: categoriesLoading } = this.props.addonsStore.addonCategories;
    const { data: addons, isLoading: addonsLoading, hasMore } = this.props.addonsStore.addons;
    return (
      <Wrapper width={1}>
        <Flex width={1} pt={95} justifyContent="space-between" bg="seaGreen" flexWrap="wrap">
          <TextWrapper width={1} justifyContent="space-between" flexDirection={['column', 'column', 'row']} pb={30} px={[15, 15, 30]}>
            <TextItem fontSize={40} color="black" fontFamily={FONT_FAMILY.HAILSR_BOLD} mr={20}>
              Extras
            </TextItem>
            <TextItem fontSize={17} fontFamily={FONT_FAMILY.HAILSR_BOOK} maxWidth="560px" alignSelf={['flex-start', 'flex-start', 'center']}>
              For companies who want to sprinkle some soul into their space, we have a list of curated extras you can add on. We do all the heavy lifting (literally), and ensure all works are done at a time which suits you so business can continue as usual.
            </TextItem>
          </TextWrapper>
        </Flex>
        <BaseLayout pt={40} pb={75}>
          <AddonFiltersForm
            initialValues={this.initialValues()}
            categories={categories}
            isLoading={categoriesLoading}
          />
          <Flex flexWrap="wrap" mx={-16}>
            {addons.map((addon, idx) => (
              <Box width={[1, 1 / 2, 1 / 3]} p={10} key={`addon-${idx}`}>
                <AddonTile {...addon} />
              </Box>
            ))}
          </Flex>
          {addonsLoading && <Loader fullPage={false} height={40} />}
          {hasMore && (
            <Flex width={1} justifyContent="center">
              <Button disabled={addonsLoading} onClick={() => this.props.addonsStore.fetchAllAddons(true)}>
                Load more
              </Button>
            </Flex>
          )}
        </BaseLayout>
      </Wrapper>
    );
  }
}

Addons.wrappedComponent.propTypes = {
  addonsStore: PropTypes.shape({
    fetchAddonCategories: PropTypes.func,
    fetchAllAddons: PropTypes.func,
    addonCategories: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
    }),
    addons: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      hasMore: PropTypes.bool,
    }),
  }).isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default Addons;

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Flex, Box, Text } from 'rebass';
import { APP_ROUTES } from '../_app/routes';
import TextInput from '../_common/components/forms/TextInput';
import { Error } from '../_common/components/forms/FormError';
import Button from '../_common/components/Button';
import { FONT_FAMILY } from '../theme';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from '../utils/formValidators';

const ForgotPasswordForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, submitError }) => (
      <form onSubmit={handleSubmit}>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" mb={40}>
          <Box width={[1, 0.5, 0.5]}>
            <Field
              label="Email"
              name="email"
              type="email"
              placeholder="email@example.com"
              component={TextInput}
              validate={composeValidators(required, mustBeValidEmail)}
              width={1}
            />
          </Box>
          <Flex width={[1, 0.5, 0.5]} justifyContent="flex-end" mt={10}>
            <Link style={{ color: 'initial' }} to={APP_ROUTES.SIGN_IN}>
              <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Sign In
              </Text>
            </Link>
          </Flex>
        </Flex>
        {submitError && (
          <Flex mt={-20} mb={20} justifyContent="center">
            <Error>{submitError}</Error>
          </Flex>
        )}
        <Flex justifyContent="center">
          <Button
            type="submit"
            loading={submitting}
            disabled={submitting}
            minWidth={211}
          >
            Send Password Reset Email
          </Button>
        </Flex>
      </form>
    )}
  />
);

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;

import get from 'lodash/get';
import { action, extendObservable } from 'mobx';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

const initialState = {
  isLoading: false,
  members: [],
  member: {},
  error: null,
};

export class MembersStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetchMembers = async (values) => {
    try {
      this.isLoading = true;
      const { data: { data } } = await API(API_ROUTES.TEAM, {
        params: {
          query: get(values, 'query'),
        },
      });
      this.members = data;
    } catch (e) {
      const error = e.error || e.message;
      this.error = error;
      toast.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchAdmins = async () => {
    try {
      this.isLoading = true;
      const { data: { data } } = await API(API_ROUTES.ADMINS);
      this.members = data;
    } catch (e) {
      const error = e.error || e.message;
      this.error = error;
      toast.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateMember = async (values) => {
    try {
      this.isLoading = true;
      const { data: { data } } = await API.put(API_ROUTES.MEMBER_BY_ID(values._id), {
        ...values,
        firstName: get(values, 'fullName', '').split(' ')[0],
        lastName: get(values, 'fullName', '').split(' ')[1],
      });
      this.members = this.members.map((member) => {
        if (member._id === data._id) {
          return { ...member, ...data };
        }
        return member;
      });
    } catch (e) {
      const error = e.error || e.message;
      this.error = error;
      toast.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteMember = async (id) => {
    try {
      this.isLoading = true;
      await API.delete(API_ROUTES.MEMBER_BY_ID(id));
      this.members = this.members.filter(member => member._id !== id);
    } catch (e) {
      const error = e.error || e.message;
      this.error = error;
      toast.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action resendInvite = async ({ email }) => {
    try {
      this.isLoading = true;
      await API.post(API_ROUTES.RESEND_INVITE, { email });
      toast.info('If email exists, we\'ll resend your invitation to it.');
    } catch (e) {
      toast.error(e.message || 'Something went wrong. Please, try again later.');
    } finally {
      this.isLoading = false;
    }
  };
}

export default new MembersStore();

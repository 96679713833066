import styled from 'styled-components';
import { FONT_FAMILY } from '../../../theme';

const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 17px;
  font-family: ${props => props.theme.fonts[FONT_FAMILY.HAILSR_BOLD]};
`;

export default Label;

import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import last from 'lodash/last';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { action, observable, computed } from 'mobx';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

const listInitialState = {
  items: [],
  isLoading: false,
  hasMore: true,
  page: 1,
};

export class ChatStore {
  SLACK_ARCHIVE_ERROR = 'is_archived';
  POLLING_INTERVAL = 5000;
  DEFAULT_PAGE_SIZE = 20;

  @observable isSendingMessage = false;
  @observable chatSupport = {};
  @observable chatWizard = {};
  @observable currentChat = { ...listInitialState };
  @observable chatsList = { ...listInitialState };

  @action setChatWizardField = (values = {}) => {
    this.chatWizard = {
      ...this.chatWizard,
      ...values,
    };
  };

  @action fetchChats = async () => {
    if (this.chatsList.isLoading || !this.chatsList.hasMore) {
      return;
    }
    this.chatsList.isLoading = true;
    try {
      const {
        data: { data = [] },
      } = await API(`${API_ROUTES.CHATS}?page=${this.chatsList.page}&limit=${this.DEFAULT_PAGE_SIZE}`);
      this.chatsList.items = [...data, ...this.chatsList.items];
      this.chatsList.hasMore = data.length === this.DEFAULT_PAGE_SIZE;
      this.chatsList.page++;
      if (this.chatsList.items.length === 0) {
        this.chatWizard.step = 1;
      }
    } catch (e) {
      toast.error('Service unavailable');
    } finally {
      this.chatsList.isLoading = false;
    }
  };

  @action fetchCurrentChat = async () => {
    if (this.currentChat.isLoading || !this.currentChat.hasMore) {
      return;
    }
    this.currentChat.isLoading = true;
    try {
      const {
        data: {
          data: { messages = [], _id, closed },
        },
      } = await API(`${API_ROUTES.CHAT_CHANNEL(this.chatWizard.chatId)}?limit=${this.DEFAULT_PAGE_SIZE}&firstId=${this.currentChat.firstId}`);
      this.currentChat = {
        hasMore: messages.length === this.DEFAULT_PAGE_SIZE,
        page: this.currentChat.page + 1,
        items: [...this.currentChat.items, ...messages],
        firstId: get(last(messages), '_id'),
        closed,
      };
      this.setChatWizardField({ chatId: _id, step: 3 });
    } catch (e) {
      this.chatWizard.step = 0.5;
    } finally {
      this.currentChat.isLoading = false;
    }
  };

  @action createChannel = async ({ topic, description }) => {
    try {
      const response = await API.post(API_ROUTES.CHATS, { ...this.chatWizard, topic, description });
      const chatId = get(response, 'data.data._id');
      this.setChatWizardField({ chatId, step: 3 });
      await this.fetchCurrentChat();
    } catch (e) {
      toast.error(e.error || e.message || 'Error!');
      this.resetChat();
    }
  };

  @computed get orderedMessages() {
    return this.currentChat.items.slice().reverse();
  }

  @action addUserMessage = async (msg = '', cb) => {
    if (!msg.trim() || this.isSendingMessage) {
      return;
    }
    this.isSendingMessage = true;
    try {
      const {
        data: { data },
      } = await API.post(API_ROUTES.CHAT_MESSAGE(this.chatWizard.chatId), {
        message: msg,
      });
      this.currentChat = {
        ...this.currentChat,
        items: [data, ...this.currentChat.items],
        closed: false,
      };
      cb();
    } catch (e) {
      if (get(e, 'data.error') === this.SLACK_ARCHIVE_ERROR) {
        return toast.error('This chat was closed by Yours support');
      }
      toast.error(e.error || e.message || 'Error!');
    } finally {
      this.isSendingMessage = false;
    }
  };

  @action startPollingMessages = async (scrollDownCb) => {
    this.interval = setInterval(async () => {
      if (!this.currentChat.closed) {
        try {
          const {
            data: {
              data: { messages, closed },
            },
          } = await API.get(`${API_ROUTES.CHAT_CHANNEL(this.chatWizard.chatId)}?lastId=${last(this.currentChat.items)._id}`);
          if (get(first(messages), '_id') !== get(first(this.currentChat.items), '_id')) {
            setTimeout(() => {
              scrollDownCb();
            }, 100);
          }
          this.currentChat = {
            ...this.currentChat,
            items: uniqBy([...messages, ...this.currentChat.items], '_id'),
            closed,
          };
        } catch (e) {
          toast.error('This chat was closed by Yours support');
          this.resetChat();
        }
      }
    }, this.POLLING_INTERVAL);
  };

  @action resetChat = () => {
    this.chatWizard = {};
    this.currentChat = { ...listInitialState };
    this.chatsList = { ...listInitialState };
    clearInterval(this.interval);
  };

  @action fetchSupportContact = async () => {
    if (isEmpty(this.chatSupport)) {
      try {
        const {
          data: { data },
        } = await API(API_ROUTES.USERS_SUPPORT_CONTACT);
        this.chatSupport = data;
      } catch (e) {
        // handle error
      }
    }
  };
}

export default new ChatStore();

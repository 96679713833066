import React, { Component } from 'react';
import { Flex, Text } from 'rebass';
import Button from '../_common/components/Button';
import { FONT_FAMILY } from '../theme';
import SignUpSteps from './SignUpSteps';
import StripeCheckout from '../payments/components/StripeCheckout';

class Stripe extends Component {
  render() {
    return (
      <StripeCheckout>
        {({ isLoading, onClick }) => (
          <>
            <SignUpSteps step={1} />
            <Text
              fontSize={35}
              fontFamily={FONT_FAMILY.HAILSR_BOOK}
              textAlign="center"
              color="black"
              lineHeight={1}
              my={40}
            >
              Stripe
            </Text>

            <Flex width={1} flexWrap="wrap" flexDirection="column" alignItems="center">
              <Button disabled={isLoading} onClick={onClick}>
                Set up payment method
              </Button>
            </Flex>
          </>
        )}
      </StripeCheckout>
    );
  }
}

export default Stripe;

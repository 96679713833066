import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import qs from 'query-string';
import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import API, { setAuthenticationToken } from '../_app/api';
import { TOKEN_KEY, ROLE } from '../_app/constants';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';
import routerStore from './routerStore';
import userStore from './userStore';

const { REACT_APP_ENV } = process.env;

export class AuthStore {
  @observable isAuthenticated = !!localStorage.getItem(TOKEN_KEY);
  @observable isLoading = true;

  constructor() {
    this.handleSubdomainChange();
  }

  @action handleSubdomainChange = () => {
    const { search } = routerStore.location || {};
    const { token, redirect } = qs.parse(search);
    if (token) {
      this.isAuthenticated = true;
      localStorage.setItem(TOKEN_KEY, token);
      routerStore.push(redirect);
    }
  };

  @action
  signIn = async ({ email, password }) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(API_ROUTES.SIGN_IN, { email, password });
      setAuthenticationToken(token);
      this.isAuthenticated = true;
      await this.handleSignInRedirection(APP_ROUTES.ROOT);
    } catch (e) {
      return { [FORM_ERROR]: 'Invalid email or password!' };
    } finally {
      this.isLoading = false;
    }
  };

  @action
  signInWithToken = async ({ password, token }) => {
    this.isLoading = true;
    try {
      const { data } = await API.post(API_ROUTES.SIGN_IN_WITH_TOKEN(token), { password });
      setAuthenticationToken(data.token);
      this.isAuthenticated = true;
      await this.handleSignInRedirection(APP_ROUTES.STRIPE, APP_ROUTES.ROOT);
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    } finally {
      this.isLoading = false;
    }
  };

  @action handleSignInRedirection = async (adminRedirect, secondaryAdminRedirect) => {
    await userStore.fetchProfile();
    const profile = { ...userStore.profile };
    if (profile.role === ROLE.MEMBER) {
      this.logout();
      return routerStore.push(`${APP_ROUTES.MEMBER_WELCOME}?name=${profile.firstName}`);
    }
    if (REACT_APP_ENV === 'production') {
      const userSubdomain = get(profile, 'space.subdomain', 'app.yours');
      const currentSubdomain = get(window.location.host.split('.work.life'), '[0]');
      if (currentSubdomain !== userSubdomain) {
        const token = localStorage.getItem(TOKEN_KEY);
        const redirect = profile.role === ROLE.SUPER_ADMIN ? adminRedirect : secondaryAdminRedirect;
        window.location.replace(`https://${userSubdomain}.work.life?token=${token}&redirect=${redirect}`);
      }
    }
    if (profile.role === ROLE.SUPER_ADMIN) {
      routerStore.push(adminRedirect);
    } else {
      routerStore.push(secondaryAdminRedirect);
    }
  };

  @action
  signUp = async (values) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(API_ROUTES.SIGN_UP, values);
      setAuthenticationToken(token);
      this.isAuthenticated = true;
    } catch (e) {
      if (e.errors) {
        return e.errors;
      }
      return { [FORM_ERROR]: get(e, 'error', 'Invalid email or password!') };
    } finally {
      this.isLoading = false;
    }
  };

  @action logout = async () => {
    this.isAuthenticated = false;
    localStorage.removeItem(TOKEN_KEY);
    this.resetAllStores();
    routerStore.push(APP_ROUTES.SIGN_IN);
  };

  @action resetPassword = async ({ token, password }, successCb) => {
    this.isLoading = true;
    try {
      await API.post(API_ROUTES.RESET_PASSWORD(token), { password });
      successCb();
    } catch (e) {
      return { [FORM_ERROR]: e.message || e.error || 'Error' };
    } finally {
      this.isLoading = false;
    }
  };

  @action resetPasswordRequest = async ({ email }) => {
    this.isLoading = true;
    try {
      await API.post(API_ROUTES.RESET_PASSWORD(), { email });
      toast.info('We sent you password reset link. Check your email!');
    } catch (e) {
      return { [FORM_ERROR]: e.message || e.error || 'Error' };
    } finally {
      this.isLoading = false;
    }
  };

  @action resetAllStores = () => {};
}

export default new AuthStore();

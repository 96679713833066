import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Flex, Text } from 'rebass';
import { CARD_ASSIGNMENT_STATUS } from '../_app/constants';
import Button from '../_common/components/Button';
import TextInput, { TEXT_INPUT_VARIANT } from '../_common/components/forms/TextInput';
import HorizontalLine from '../_common/components/HorizontalLine';
import { BUTTON_VARIANT, FONT_FAMILY } from '../theme';
import { composeValidators, mustBeValidEmail, required } from '../utils/formValidators';
import AsyncAutocomplete from '../_common/components/forms/AsyncAutocomplete';
import { API_ROUTES } from '../_app/routes';

const onKeyDown = (e) => {
  if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === 13) {
    if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
      e.preventDefault();
      return false;
    }
  }
};

const UserDetailsForm = ({ initialValues, onSubmit, resendInvite }) => {
  const [rfid, setRfid] = useState('');
  const [cardAssignmentStatus, setCardAssignmentStatus] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        cardStatus: CARD_ASSIGNMENT_STATUS.NOT_ASSIGNED,
        fullName: [initialValues.firstName, initialValues.lastName].join(' '),
        ...initialValues,
      }}
      render={({
        handleSubmit,
        submitting,
        values,
        form: { change },
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex>
            <Box width={1} mr={10}>
              <Field
                name="email"
                component={TextInput}
                placeholder="email@example.com"
                label="Email Address"
                validate={composeValidators(required, mustBeValidEmail)}
                variant={TEXT_INPUT_VARIANT.GHOST_SECONDARY}
              />
            </Box>
            <Box width={1} ml={10}>
              <Field
                name="fullName"
                component={TextInput}
                placeholder="Full name (optional)"
                label="Full Name"
                variant={TEXT_INPUT_VARIANT.GHOST_SECONDARY}
              />
            </Box>
          </Flex>
          <HorizontalLine my={40} />
          <Flex>
            <Box width={1} mr={10}>
              <Field
                name="cardStatus"
                component={TextInput}
                label="Card Status"
                readOnly
                variant={TEXT_INPUT_VARIANT.GHOST_SECONDARY}
              />
            </Box>
            <Box width={1} ml={10}>
              <Field
                name="cardId"
                url={API_ROUTES.UNASSIGNED_CARDS}
                component={AsyncAutocomplete}
                label="Card Number"
                placeholder="N/A"
                defaultFieldValue={initialValues.cardId ? { value: initialValues.cardId, label: initialValues.cardNumber } : null}
                dataMapper={data => data.map(({ serialNumber, _id }) => ({ value: _id, label: serialNumber }))}
                variant={TEXT_INPUT_VARIANT.GHOST_SECONDARY}
              />
            </Box>
            <Box width={0} style={{ overflow: 'auto' }}>
              <input
                type="text"
                ref={ref}
                name="hiddenCardNumber"
                onChange={e => setRfid(e.target.value)}
                onBlur={() => change('cardNumber', rfid)}
                onKeyDown={debounce(() => {
                  setCardAssignmentStatus(CARD_ASSIGNMENT_STATUS.ASSIGNED);
                  change('cardStatus', CARD_ASSIGNMENT_STATUS.ASSIGNED);
                  ref.current.blur();
                }, 1000)}
              />
            </Box>
          </Flex>
          <Flex mt={25} alignItems="flex-start" justifyContent="space-between" flexWrap={['wrap', 'wrap', 'nowrap']}>
            <Box pr={10} width={[1, 'auto']}>
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setCardAssignmentStatus('Present card to reader...');
                  setRfid('');
                  ref.current.value = null;
                  ref.current.focus();
                }}
                minWidth={242}
              >
                Assign card
              </Button>
              <Box width={1}>
                {cardAssignmentStatus && (
                  <Text
                    fontFamily={FONT_FAMILY.HAILSR_BOLD}
                    fontSize={25}
                  >
                    {cardAssignmentStatus}
                  </Text>
                )}
              </Box>
            </Box>
            <Box pl={[0, 0, 10]} mt={[20, 0, 0]}>
              <Button
                type="button"
                onClick={() => {
                  resendInvite({ email: values.email });
                }}
                minWidth={242}
              >
                Resend invite
              </Button>
            </Box>
          </Flex>
          <HorizontalLine my={40} />
          <Flex>
            <Button
              variant={BUTTON_VARIANT.SECONDARY}
              ml="auto"
              type="submit"
              disabled={submitting}
              minWidth={242}
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    />
  );
};

UserDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  resendInvite: PropTypes.func,
};

export default UserDetailsForm;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useCollapse from 'react-collapsed';
import { Box, Flex, Image } from 'rebass';
import styled from 'styled-components';

const Header = styled(Box)`
  outline: none;
  cursor: pointer;
  color: ${props => props.theme.colors.seaGreen};
  background: ${props => props.theme.colors.seaGreenLight};
  padding: 13px;
  border-radius: 5px;
  position: relative;
`;

const IconWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 10px);
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-left:  1px solid rgba(24, 33, 47, 0.15);
  margin: 5px 0;
`;

const CollapsiblePanel = ({ title, children, initialIsOpen }) => {
  const [isOpen, setOpen] = useState(initialIsOpen);
  React.useEffect(() => {
    setOpen(initialIsOpen);
  }, [initialIsOpen]);
  const { getCollapseProps, getToggleProps } = useCollapse({ isOpen });
  return (
    <Flex flexDirection="column" mb={10}>
      <Header
        mb={10}
        {...getToggleProps({
          onClick: () => setOpen(oldOpen => !oldOpen),
        })}
      >
        {title}
        <IconWrapper>
          <Image src="/images/icons/panel-icon.png" />
        </IconWrapper>
      </Header>
      <Flex {...getCollapseProps()} flexDirection="column">{children}</Flex>
    </Flex>
  );
};

CollapsiblePanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  initialIsOpen: PropTypes.bool,
};

CollapsiblePanel.defaultProps = {
  initialIsOpen: false,
};

export default CollapsiblePanel;

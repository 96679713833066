import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Card, Flex, Text } from 'rebass';
import moment from 'moment';
import HorizontalLine from '../../_common/components/HorizontalLine';
import { CARD_VARIANT, FONT_FAMILY } from '../../theme';
import Loader from '../../_common/components/Loader';
import Modal from '../../_common/components/Modal';
import Button from '../../_common/components/Button';

const AddonsList = ({
  addons, onLoadMore, hasMore, error, isLoading, cancelAddon,
}) => {
  const [addonId, setAddonId] = React.useState(null);
  return (
    <Card variant={CARD_VARIANT.TERTIARY} style={{ border: 'none' }} p={25} width={1} mb={[25, 0, 0]}>
      <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} mb={25} fontSize={24}>
        Extras Status
      </Text>
      <HorizontalLine mb={25} />
      {addons.map(({
        addon = {}, occurrenceDate, status, _id, recurrenceRule,
      }, idx) => (
        <Fragment key={`invoice-item-${idx}`}>
          <Flex justifyContent="space-between" py={10} flexWrap="wrap">
            <Text width={[1, 1 / 3, 1 / 4]} fontFamily={FONT_FAMILY.HAILSR_BOOK} fontSize={17}>
              {moment(occurrenceDate).format('DD MMMM YYYY')}
            </Text>
            <Flex width={[1, 2 / 3, 3 / 4]} justifyContent="space-between" flexWrap="wrap">
              <Text fontFamily={FONT_FAMILY.HAILSR_BOOK} fontSize={17} style={{ textDecoration: 'underline' }} fontWeight="700">
                {addon.name}
              </Text>
              <Text textAlign="right" fontFamily={FONT_FAMILY.HAILSR_BOOK} fontSize={17}>
                {`${status} ${recurrenceRule ? '(recurring)' : ''}`}
              </Text>
            </Flex>
          </Flex>
          <Flex width={1} justifyContent="flex-end">
            {status !== 'cancelled' && status !== 'delivered' && (
              <Text onClick={() => setAddonId(_id)} mb={20} style={{ cursor: 'pointer' }}>
                Cancel
              </Text>
            )}
          </Flex>
        </Fragment>
      ))}
      {isLoading && <Loader fullPage={false} />}
      {isEmpty(addons) && !isLoading && (
        <Text mb={45} fontSize={20}>
          No addons found
        </Text>
      )}
      <HorizontalLine my={25} />
      {!error && !isEmpty(addons) && hasMore && (
        <Text fontSize={17} textAlign="center" onClick={() => onLoadMore()} style={{ cursor: 'pointer' }}>
          Older
        </Text>
      )}

      <Modal
        customStyles={{ content: { maxWidth: 510, padding: 20 } }}
        isOpen={!!addonId}
        onClose={() => setAddonId(null)}
        onRequestClose={() => setAddonId(null)}
      >
        <Flex alignItems="center" width={1} flexDirection="column">
          <Text fontSize={20}>Are you sure you want to cancel this extra?</Text>
          <Text fontSize={16} mt={14}>The service will be canceled within 24 hours.</Text>
          <Flex width={1} mt={30} justifyContent="space-around" flexWrap="wrap">
            <Button
              variant="secondary"
              mb={[20, 0]}
              width={[1, 9 / 20]}
              onClick={() => {
                cancelAddon({ addonId });
                setAddonId(null);
              }}
            >
              Yes
            </Button>
            <Button variant="secondary" onClick={() => setAddonId(null)} width={[1, 9 / 20]}>
              No
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Card>
  );
};

AddonsList.propTypes = {
  addons: PropTypes.array,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  cancelAddon: PropTypes.func,
};

export default AddonsList;

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Flex } from 'rebass';
import TextInput from './TextInput';
import Label from './FormLabel';

const DatePicker = ({ label, input, filterDate }) => (
  <Flex flexDirection="column">
    <Label>{label}</Label>
    <ReactDatePicker
      customInput={<TextInput meta={{}} />}
      selected={input.value ? moment(input.value).toDate() : moment().toDate()}
      onChange={input.onChange}
      dateFormat="dd MMMM yyyy"
      filterDate={filterDate}
    />
  </Flex>
);

DatePicker.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  filterDate: PropTypes.func,
};

DatePicker.defaultProps = {
  filterDate: () => true,
};

export default DatePicker;

import { action, extendObservable } from 'mobx';
import { toast } from 'react-toastify';
import moment from 'moment';
import qs from 'query-string';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import routerStore from './routerStore';

const PAGE_SIZE = 12;

const initialState = {
  addonCategories: {
    data: [],
    isLoading: true,
  },
  addons: {
    data: [],
    isLoading: true,
    hasMore: true,
    page: 1,
  },
  agreements: {
    isLoading: false,
    data: [],
    hasMore: true,
    page: 1,
  },
};

export class AddonsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action fetchAgreements = async (loadMore = false) => {
    if (loadMore) {
      this.agreements.page++;
    } else {
      this.agreements = initialState.agreements;
    }
    this.agreements.isLoading = true;
    try {
      const {
        data: { data = [], total },
      } = await API(`${API_ROUTES.MY_DOCUMENTS}?page=${this.agreements.page}&limit=${PAGE_SIZE}`);
      this.agreements.data = loadMore ? [...this.agreements.data, ...data] : data;
      if (this.agreements.data.length === total) {
        this.agreements.hasMore = false;
      }
    } catch (e) {
      toast.error(e.message || e.error || 'Network error');
      this.agreements.data = [];
    } finally {
      this.agreements.isLoading = false;
    }
  };

  @action
  fetchAddonCategories = async () => {
    this.addonCategories = { data: [], isLoading: true };
    try {
      const {
        data: { data = [] },
      } = await API(API_ROUTES.ADDON_CATEGORIES);
      this.addonCategories = { data, isLoading: false };
    } catch (e) {
      toast.error(e.message || e.error || 'Network error');
      this.addonCategories = { data: [], isLoading: false };
    }
  };

  @action fetchMyAddons = this._fetchAddons(API_ROUTES.MY_ADDONS);
  @action fetchAllAddons = this._fetchAddons(API_ROUTES.ADDONS);

  @action _fetchAddons(endpointUrl) {
    return async (loadMore = false) => {
      const { search } = routerStore.history.location;
      if (loadMore) {
        this.addons.page++;
      } else {
        this.addons = initialState.addons;
      }
      this.addons.isLoading = true;
      try {
        const {
          data: { data = [], total },
        } = await API(`${endpointUrl}?page=${this.addons.page}&limit=${PAGE_SIZE}&${this._prepareQuery(search)}`);

        this.addons.data = loadMore ? [...this.addons.data, ...data] : data;
        if (this.addons.data.length === total) {
          this.addons.hasMore = false;
        }
      } catch (e) {
        toast.error(e.message || e.error || 'Network error');
        this.addons.data = [];
      } finally {
        this.addons.isLoading = false;
      }
    };
  }

  @action
  requestAddon = async ({
    addonId, additionalInfo, recurrenceRule, startsAt, isRecurring,
  }, successCb) => {
    try {
      await API.post(API_ROUTES.REQUEST_ADDON(addonId), {
        additionalInfo,
        startsAt,
        ...(isRecurring ? { recurrenceRule } : {}),
      });
      successCb();
    } catch (e) {
      toast.error(e.message || e.error || 'Network error');
    }
  };

  @action
  cancelAddon = async ({ addonId }) => {
    try {
      await API.post(API_ROUTES.CANCEL_ADDON(addonId));
      this.addons.data = this.addons.data.map(addon => ({ ...addon, status: addonId === addon._id ? 'cancelled' : addon.status, occurrenceDate: moment().toDate() }));
    } catch (e) {
      toast.error(e.message || e.error || 'Network error');
    }
  };

  _prepareQuery = (search) => {
    const { phrase, categoryId } = qs.parse(search);
    return qs.stringify({ filters: JSON.stringify({ phrase, categoryId }) });
  };
}

export default new AddonsStore();

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Flex, Text } from 'rebass';
import Modal from '../_common/components/Modal';
import { colors } from '../theme';

const modalContentStyles = { maxWidth: 510, padding: 40, backgroundColor: colors.seaGreen };

const InvitationSuccessModal = ({ onClose, existingMembers, invitedMembers }) => (
  <Modal
    isOpen
    onClose={onClose}
    customStyles={{ content: modalContentStyles }}
    onRequestClose={onClose}
  >
    <Flex flexDirection="column">
      {!isEmpty(invitedMembers) && (
        <Fragment>
          <Text fontSize={27} mb={20} mt={15} color="#fff" textAlign="center">
            Success!
          </Text>
          <Text fontSize={23} mb={20} color="#fff" textAlign="center">
            Those invites have been sent!
          </Text>
        </Fragment>
      )}
      {!isEmpty(existingMembers) && (
        <Fragment>
          <Text fontSize={23} mb={15} color="#fff" textAlign="center">
            {`Those emails already exist: ${existingMembers.map(member => member.email).join(', ')}`}
          </Text>
        </Fragment>
      )}
    </Flex>
  </Modal>
);

InvitationSuccessModal.propTypes = {
  onClose: PropTypes.func,
  existingMembers: PropTypes.array.isRequired,
  invitedMembers: PropTypes.array.isRequired,
};

export default InvitationSuccessModal;
